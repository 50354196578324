import {Component, OnInit} from '@angular/core';
import {NpUser} from '@nextpage/np-sdk-data';
import {filter, flatMap} from 'rxjs/internal/operators';
import {SpAuthenticationService} from '../../../data/services/sp-authentication.service';
import {SpUserInfoService} from '@data/services';
import {Router} from '@angular/router';
import {RouteName} from '../../../data/constants/route.constants';
import {RouteResolverService} from '../../../data/services/routes/route-resolver.service';

@Component({
    selector: 'app-user-name',
    templateUrl: './user-name.component.html',
    styleUrls: ['./user-name.component.scss'],
})
export class UserNameComponent implements OnInit {
    public currentUser: NpUser;

    constructor(
        private _userInfoService: SpUserInfoService,
        private _authenticationService: SpAuthenticationService,
        private _router: Router,
        private _routeResolverService: RouteResolverService
    ) {
    }

    ngOnInit(): void {
        this._fetchUserInfos();
    }

    onLogout() {
        this._authenticationService.logout();
        return this._routeResolverService.navigate(RouteName.LOGIN);
    }

    goToAccount() {
        return this._routeResolverService.navigateToCurrentEntityRoute(RouteName.ACCOUNT);
    }

    private _fetchUserInfos() {
        this._authenticationService
            .afterAuth()
            .pipe(
                filter((response) => {
                    return response;
                }),
                flatMap(() => {
                    return this._userInfoService.getCurrentUser();
                })
            )
            .subscribe((response) => {
                this.currentUser = response;
            });

        this._userInfoService.getCurrentUser().subscribe((response) => {
            this.currentUser = response;
        });
    }
}
