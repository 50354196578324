import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, zip} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';

import {CustomFieldDto, NPElement, NpUser, NpUserInfoService, SupplierInfo} from '@nextpage/np-sdk-data';
import {SpDicoCarac} from '@data/constants';
import {NpCaracConfigService} from './np-carac-config.service';
import {environment} from '@env';

@Injectable({
    providedIn: 'root',
})
export class SpUserInfoService {
    private _currentUser: NpUser;
    private _currentUser$ = new BehaviorSubject<NpUser>(null);

    constructor(private _userInfoService: NpUserInfoService, private _caracConfigService: NpCaracConfigService) {
    }

    public getCurrentUser(): Observable<NpUser> {
        return this._userInfoService.getCurrentUser().pipe(
            map(users => {
                this._currentUser = users && users.length > 0 ? users[0] : null;
                this._currentUser$.next(this._currentUser);
                return this._currentUser;
            })
        );
    }

    public getUserCustomFieldByExtId(fieldExtId: string): Observable<CustomFieldDto> {
        return this._currentUser$.asObservable().pipe(
            filter(response => response !== null && response !== undefined),
            map(user => {
                return user && user.CustomFields ? user.CustomFields.find(x => x.ExtID === fieldExtId) : null;
            })
        );
    }

    public getImportProfileExtId(): Observable<string> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_PROFIL_IMPORT_AVANCE)
            .pipe(
                switchMap(fieldContent => {
                    return fieldContent &&
                    fieldContent.Values &&
                    fieldContent.Values.length > 0 ?
                        of(fieldContent.Values[0].Value) : null;
                })
            );
    }

    /**
     * Retourne l'extId cu canal qui sera utilisé dans la recherche avancée
     * pour récupérer les Produits
     */
    public getChannelExtId(): Observable<CustomFieldDto> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_EXTID_CHANNEL);
    }

    public getMediasExtId(): Observable<CustomFieldDto>{
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_ID_MEDIAS);
    }

    public getFamilyExtId(): Observable<CustomFieldDto> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_PRODUIT);
    }

    public getParentExtId(): Observable<CustomFieldDto> {
        return this.getChannelExtId().pipe(
            switchMap(result => {
                environment.useChannelSearch = result?.Values?.length > 0;
                return result?.Values?.length > 0 ? of(result) : this.getFamilyExtId()
            })
        )
    }

    buildSupplierModelFromFamily(elements: NPElement): SupplierInfo {
        if (elements && elements.Values) {
            return {
                Logo: elements.Values.get(SpDicoCarac.CP_LOGO),
                Raison_sociale: elements.Values.get(SpDicoCarac.CP_RAISON_SOCIAL),
                Adresse: elements.Values.get(SpDicoCarac.CP_ADRESSE),
                Code_postal: elements.Values.get(SpDicoCarac.CP_CODEPOSTAL),
                Ville: elements.Values.get(SpDicoCarac.CP_VILLE),
            };
        }
    }

    public getUserCustomFieldsByExtIDs(fieldExtIDs: string[]): Observable<CustomFieldDto[]> {
        return this._currentUser$.asObservable().pipe(
            filter(response => response !== null && response !== undefined),
            map(user => {
                return user && user.CustomFields ? user.CustomFields.filter(x => fieldExtIDs.some(z => x.ExtID === z)) : [];
            })
        );
    }

    public getConfigByUserField(fieldExtID: string) {
        return zip(this._caracConfigService.getCurrentCaracConfigs(), this.getUserCustomFieldsByExtIDs([fieldExtID])).pipe(
            map(([configs, userFields]) => {
                const descFields = userFields.find(field => field.ExtID === fieldExtID);
                const fieldValues = descFields.Values[0].Value.split(';');
                const fieldConfig = configs.filter(c => fieldValues.some(f => c.DicoCaracExtID === f));
                return configs.filter(c => fieldValues.some(f => c.DicoCaracExtID === f));
            })
        );
    }
}
