import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Field} from '@data/types';
import {ProductsFacade} from '@data/facades';
import {NPElement} from "@nextpage/np-sdk-data";
import {ProductSummary} from "@data/models";

@Component({
    selector: 'app-dialog-product-sheet',
    templateUrl: './dialog-product-sheet.component.html',
    styleUrls: ['./dialog-product-sheet.component.scss']
})
export class DialogProductSheetComponent implements OnInit, OnDestroy {
    public fields: Field[];
    public fields$: Subscription;
    public loading: boolean = false;
    public linkedElements: { urlImage: string; label: string }[];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ProductSummary,
        private productFacade: ProductsFacade
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.fields$ = this.productFacade.getInfoForProduct(this.data).subscribe(result => {
            this.loading = false;
            this.fields = result.fields;
            this.linkedElements = result.linkedElementsProducts
        });
    }

    ngOnDestroy() {
        this.fields$.unsubscribe();
    }

    print() {
        window.print();
    }
}
