import {Component, Input} from '@angular/core';
import {NpCard, ProductCardAction} from '../../../data/models/np-card';
import {ProductCardActionBuilder} from '../../../data/models/product-card-action-builder';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss']
})
export class CardsComponent {
    private _cards: NpCard[];
    @Input() actions: ProductCardAction;

    constructor() {

    }
}
