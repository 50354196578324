import {Access, NPElement, TypeCode} from '@nextpage/np-sdk-data';
import {Field} from '../types/field';
import {Cards} from "../types/cards";
import {NpStatusAcces} from '../../../lib/data/model/np-status-acces';

export interface Message {
    cssClass?: string;
    type?: string;
    text?: string;
}

export interface SpRouteData {
    title: string;
    pageTitle: string;
    displayFullScreen: boolean;
}

export class ElementSummary {
    select? = '';
    overview? = '';
    label = '';
    modificationDate = '';
    productLength?: '';
    productPrice?: '';
    productWeight?: '';
    urlImage?: string;
    productColor?: string[];
    actions = '';
    element: NPElement;
    lastModificationUserName: string;
    isFavoriteElement?: boolean;
    urlWithoutToken: string;
}

export class ProductSummary {
    public fields: Field[];

    constructor(
        public label: string,
        public modificationDate: string,
        public element: NPElement,
        public lastModificationUserName: string,
        public urlImage: string = '',
        public urlPdf: string = '',
        public action: string = '',
        public urlWithoutToken: string = '',
        public status: NpStatusAcces = null
    ) {
        this.fields = [];
    }
}

export class MediaSummary {
    public label: string;
    public element: NPElement;
    public modificationDate: string | Date;
    public downloadUrl?: {label: string, url: string}[];
    public urlImage: string = '';
    public action: string = '';
}

export class Characteristic {
    constructor(private _label: string, public ExtID: string, public _typeCode: TypeCode) {
    }

    get label(): string {
        return this._label;
    }
    get typeCode(): TypeCode {
        return this._typeCode;
    }
}

export class DataSummary {
    headers: string[] = [];
    headers2: string[] = [];
    data: any[] = [];
    count: number;
    AllData: any[] = [];
}

export interface SystemLastModificationDate {
    Access: Access;
    ContextID: number;
    DicoCaracExtID: string;
    DicoCaracID: number;
    Element?: NPElement;
    ElementExtID: string;
    ElementID: number;
    LangID: number;
    LastModifiedDate: string;
    ModifiedByUserID: number;
    ModifiedByUserName: string;
    TypeValue: number;
}
