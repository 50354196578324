import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {CaracInfo} from '../np-value/Model';
import {
    CaracConfig,
    DicocaracRepository,
    ElementWriterService,
    NPCaracListe,
    NPListeValues
} from '@nextpage/np-sdk-data';


@Component({
    selector: 'lib-select-list-value',
    templateUrl: './select-list-value.component.html',
    styleUrls: ['./select-list-value.component.css']
})
export class SelectListValueComponent implements OnInit, OnChanges {

  @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracListe;
    @Input() caracConfig: CaracConfig;

    public displayValues: NPListeValues[];
    public selectedValue: NPListeValues;
    public dropDownControl = new UntypedFormControl();
    private _formCtrlSub: Subscription;

    public displayRequiedError = false;

    constructor(private _dicoCaracRep: DicocaracRepository, private _eltWrSrv: ElementWriterService) {
    }

    ngOnInit() {
        this._formCtrlSub = this.dropDownControl.valueChanges
            .subscribe((newValue: NPListeValues) => {
                this.selectOption(newValue);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initValues();
    }

    public initValues() {
        if (this.value != null) {
            if (this.value.Element) {
                this.value.ElementExtID = this.value.Element.ExtID;
            }
            // Initialisation des valeurs à afficher
            this.displayValues = this.caracConfig.DicoCarac.Values;
            // Initialisation de la valeur enregistrée (si présente)
            if (this.value.Values != null && this.value.Values.length > 0) {
                this.selectedValue = this.displayValues.filter(value => value.ValeurID === this.value.Values[0].ValueID)[0];
                this.dropDownControl.setValue(this.selectedValue);
            }
        } else {
            this.displayValues = [];
        }
    }

    /**
     * Sélectionne l'option
     */
    private selectOption(valeur: NPListeValues) {
        this.selectedValue = valeur;
        const newValues: number[] = [];
        if (valeur != null) {
            newValues.push(valeur.ValeurID);
        }
        // Pour ne pas mettre ajour si la valeur n'a pas changé
        const oldValues = this.value.Element.getValueListe(this.value.DicoCaracExtID);
        if (!oldValues.Values.some(caracValue => caracValue.ValueID === valeur.ValeurID)) {
            this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, newValues);
        }
    }
}
