import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogLoaderComponent} from '../dialog-loader/dialog-loader.component';
import {SnackBarComponent} from '../snack-bar/snack-bar.component';

@Injectable({
    providedIn: 'root',
})
export class SpLoaderService {
    private _dialog: MatDialogRef<DialogLoaderComponent>;

    constructor(public _dialogRef: MatDialog, private _snackBar: MatSnackBar) {
    }

    public openPreloader() {
        this._dialog = this._dialogRef.open(DialogLoaderComponent, {
            hasBackdrop: true,
            disableClose: true,
        });
    }

    public close() {
        if (this._dialog) {
            this._dialog.close();
        }
    }


    openSnackBar(message: string) {
        this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            data: {message: message},
        });
    }
}
