<h2 mat-dialog-title class="dialog-title">{{dialogTitle}}</h2>
<mat-dialog-content>
    <ng-container *ngIf="currentPhoto">
        <div class="gallery-viewer">
            <img [src]="currentPhoto.src" [alt]="currentPhoto.alt" [title]="currentPhoto.alt">
        </div>
    </ng-container>
    <ng-container *ngIf="photoUrls.length > 1">
        <div class="gallery-list">
            <div class="photo-legend" [ngClass]="isSelected(photo) ? 'photo-legend-active' : ''" *ngFor="let photo of photoUrls" (click)="circleSelected(photo)"></div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions *ngIf="isAllowed">
    <button class="btn btn-primary np-button np-close-btn"
            type="button"
            title="Fermer"
            (click)="close()">
        <i class="ti ti-close"></i> Fermer
    </button>
    <button class="btn-np btn-primary-np np-button custom-button"
            type="button"
            title="Modifier cette image"
            (click)="changePicture(currentPhoto)">
        <i class="glyphicon glyphicon-pencil"></i> Modifier
    </button>
    <button class="btn-np btn-primary-np np-button custom-button"
            type="button"
            title="Supprimer cette image"
            (click)="deletePicture(currentPhoto)">
        <i class="glyphicon glyphicon-remove"></i> Supprimer
    </button>
</mat-dialog-actions>
