<div id="element-page">
    <div id="element-page-header">
        <app-np-elements-table-header [filterTitle]="'Filtres'">
            <app-product-filter-view></app-product-filter-view>
        </app-np-elements-table-header>
    </div>

    <div id="element-page-content">
        <app-elements-table [elements]="elements"></app-elements-table>
    </div>
</div>
