<div id="no-instance-page">
    <mat-card>
        <mat-card-content>
            <div>
                <span class="material-icons">remove_circle_outline</span>
                <span>Aucune instance trouvée.</span>
            </div>
            <div>
                <span>Veuillez renseigner le nom correct dans l'url.</span>
                <span>Exemple: https://mon-domaine.fr/login/mon-instance</span>
                <span>Ou contactez un administrateur NEXTPAGE@</span>
            </div>
        </mat-card-content>
    </mat-card>
</div>
