import {NPElement} from './np-element';


export class NPAPIElementLinksResult {
  Results: number[];

  Elements: Map<string, NPElement>;

  constructor(data: Object) {
    if (data == null) {
      return null;
    }
    if (data.hasOwnProperty('ResultsExtIDs')) {
      this.Results = data['ResultsExtIDs'];
    }
    if (data.hasOwnProperty('Elements')) {
      this.Elements = new Map<string, NPElement>();
      Object.keys(data['Elements']).map((key) => {
        this.Elements.set(key, new NPElement(data['Elements'][key]));
      });
    }
  }
}
