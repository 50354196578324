import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {CaracConfigRepositoryService} from "./carac-config-repository.service";
import {ExportExcelSummary} from "../models/export-models";
import {EXPORT_OBJECT} from "../constants/export";

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
  private _objectToSend: any;
  private _url = '/api/sdk/exportdata/add';
  private _errMessage = `Une erreur s'est produite lors de la génération du fichier de l'export`;

  constructor(private _http: HttpClient,
              private _CaracConfigService: CaracConfigRepositoryService) {

  }

  sendObject(data: ExportExcelSummary) {
    if (data) {
      this._objectToSend = Object.assign({}, EXPORT_OBJECT);
      this._objectToSend.Name = data.fileName ? `EXPORT_${data.fileName}` : 'EXPORT_PRODUCT';
      this._objectToSend.Config.JobImportExportConfigExtID = data.profileExportName;
      this._objectToSend.Config.OutFileName = data.fileName ? `EXPORT_${data.fileName}` : 'EXPORT_PRODUCT';
      this._objectToSend.Config.Produits = data.productsId;
      this._objectToSend.Config.User.Email = data.userEmail;
      this._objectToSend.Config.User.ID = data.userID;
        return this._sendToNP();
    } else {
      return throwError(this._errMessage);
    }
  }

  private _sendToNP() {
    return this._http.post(this._url, this._objectToSend)
        .pipe(
            catchError(() => throwError(this._errMessage)),
            flatMap((response) => {
              return response ? of(response) : throwError(this._errMessage);
            })
        );
  }
}
