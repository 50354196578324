import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-product-filter-view',
  templateUrl: './product-filter-view.component.html',
  styleUrls: ['./product-filter-view.component.scss']
})
export class ProductFilterViewComponent implements OnInit{

  testData: any[];
  formGroup: FormGroup;

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      selectedCity: new FormControl<any | null>(null)
    });

    this.testData = [
      { name: 'Product type 1', code: 'NY' },
      { name: 'Product type 2', code: 'NY' },
      { name: 'Product type 3', code: 'NY' },
      { name: 'Product type 4', code: 'NY' },
    ];
  }

}
