import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogService} from '../services/dialog.service';
import {ElementRepository, FileReaderService} from '@nextpage/np-sdk-graphics';
import {Cards} from '@data/types';
import {Observable} from 'rxjs';
import {Favorite} from '@data/models';
import {selectFavoritesList} from '@store/selector';
import {ProductsFacade} from '@data/facades';
import {DialogProductSheetComponent} from '../dialog-product-sheet/dialog-product-sheet.component';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {FavoriteActions} from '@store/action';
import {DialogMediaSheetComponent} from '../dialog-media-sheet/dialog-media-sheet.component';
import {NpInstanceService} from '../../../data/services/np-instance.service';
import {EntityName} from '../../../../lib/data/model/portal';

@Component({
    selector: 'app-media-card',
    templateUrl: './media-cards.component.html',
    styleUrls: ['./media-cards.component.scss'],
})
export class MediaCardsComponent {
    @Input() filteredCard: Cards[];
    @Output() isAllSelected = new EventEmitter<boolean>();
    favoritesState$: Observable<Favorite[]>;

    constructor(
        private dialog: MatDialog,
        private _fileReaderImage: FileReaderService,
        private store: Store,
        private _elementRepository: ElementRepository,
        private _dialogService: DialogService,
        private _fileReaderService: FileReaderService,
        private productsFacade: ProductsFacade,
        private _instanceService: NpInstanceService
    ) {
        this.favoritesState$ = this.store.select(selectFavoritesList);
    }

    moreInfo(card) {
        if (this._instanceService.currentEntity.name === EntityName.Supplier || this._instanceService.currentEntity.name === EntityName.Consultation) {
            this.dialog.open(DialogProductSheetComponent, {
                hasBackdrop: true,
                disableClose: false,
                height: '95%',
                width: '90%',
                data: card,
            });
        } else {
            const mediaSize = card.element.getValueLien('DC_MEDIA_METADATA_Size')?.Value;
            const mediaSizeInMo = ((mediaSize || 0) / 1048576).toFixed(2);
            this.dialog.open(DialogMediaSheetComponent, {
                hasBackdrop: true,
                disableClose: false,
                height: '95%',
                width: '90%',
                data: {...card, mediaSizeInMo}
            });
        }
    }

    toggleFavorite(elementSummery: Cards, $event: Event) {
        $event.stopPropagation();
        elementSummery.isFavoriteElement = !elementSummery.isFavoriteElement;
        this.store.dispatch(
            FavoriteActions.toggleFavorite({
                favorites: {
                    extID: elementSummery.element.ExtID,
                    urlImage: '',
                    label: elementSummery.label,
                    element: elementSummery.element,
                    modificationDate: elementSummery.modificationDate,
                    selected: elementSummery.isFavoriteElement,
                    urlWithoutToken: elementSummery.urlWithoutToken
                },
            })
        );
    }
}
