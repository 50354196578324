import { NPElementType } from '@nextpage/np-sdk-data';
import { Chars } from '../types';

export class ParamsFilter {
  constructor(
    private _numberOfElementByPage: number,
    private _page: number,
    private _keyword: string,
    private _elementTypeList: NPElementType[],
    private _productTypeId?: number,
    private _facetsList?: string[],
    private _chars?: Chars
  ) {}

  get numberOfElementByPage(): number {
    return this._numberOfElementByPage;
  }

  get page(): number {
    return this._page;
  }

  get keyword(): string {
    return this._keyword;
  }

  get elementTypeList(): NPElementType[] {
    return this._elementTypeList;
  }

  get productTypeId(): number {
    return this._productTypeId;
  }

  get facetsList(): string[] {
    return this._facetsList;
  }

  get chars(): Chars {
    return this._chars;
  }
}
