import {ParamsFilter} from '../models/params-filter';
import {NPElementType} from '@nextpage/np-sdk-data';
import {Chars} from '../types';

export class ParamsFilterBuilder {
    private _numberOfElementByPage: number;
    private _page: number;
    private _keyword: string;
    private _elementTypes: NPElementType[];
    private _productTypeId: number;
    private _facetList: string[];
    private _chars: Chars;

    withNumberOfElementByPage(value: number): ParamsFilterBuilder {
        this._numberOfElementByPage = value;
        return this;
    }

    withPage(value: number): ParamsFilterBuilder {
        this._page = value;
        return this;
    }

    withKeyword(value: string): ParamsFilterBuilder {
        this._keyword = value;
        return this;
    }

    withElementTypes(value: NPElementType[]): ParamsFilterBuilder {
        this._elementTypes = value;
        return this;
    }

    withProductTypeId(value: number): ParamsFilterBuilder {
        this._productTypeId = value;
        return this;
    }

    withFacetList(value: string[]): ParamsFilterBuilder {
        this._facetList = value;
        return this;
    }

    withChars(value: Chars): ParamsFilterBuilder {
        this._chars = value;
        return this;
    }

    build(): ParamsFilter {
        return new ParamsFilter(
            this._numberOfElementByPage ?? 10,
            this._page ?? 1,
            this._keyword ?? '',
            this._elementTypes ?? [],
            this._productTypeId,
            this._facetList,
            this._chars,
        );
    }
}
