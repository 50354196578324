<ng-container *ngIf='dataSource && headerLabels'>
  <div id='product-table'>
    <table mat-table [dataSource]='dataSource' multiTemplateDataRows>
      <ng-container [matColumnDef]='column' *ngFor='let column of columnsToDisplay'>
        <th mat-header-cell *matHeaderCellDef>
<!--       On garde code, car on sait jamais si il faut revenir en arrière, encore ... -->
<!--          <ng-container *ngIf="column === 'select'">-->
<!--            <mat-checkbox-->
<!--              (change)='$event ? toggleAllRows() : null'-->
<!--              [checked]='selection.hasValue() && isAllSelected()'-->
<!--              [indeterminate]='selection.hasValue() && !isAllSelected()'-->
<!--            >-->
<!--            </mat-checkbox>-->
<!--          </ng-container>-->
          {{ headerLabels[column] }}
        </th>
        <td mat-cell *matCellDef='let element' title="Cliquez pour afficher plus d'informations">
          <ng-container *ngIf="column === 'select'; else viewProductTable">
            <mat-checkbox
              (click)='$event.stopPropagation(); getSelectedValue(element)'
              (change)='$event ? selection.toggle(element) : null'
              [checked]='selection.isSelected(element)'
            >
            </mat-checkbox>
          </ng-container>
          <ng-template #viewProductTable>
            <ng-container *ngIf="column === 'overview'; else viewProductDetails">
              <img
                [src]='element.urlImage'
                class='imageUrlStyle'
                height='125'
                width='190'
                alt='Aperçu du média'
              />
            </ng-container>
            <ng-template #viewProductDetails>
              <app-product-table-cell-view [element]='element' [column]='column'>
              </app-product-table-cell-view>
            </ng-template>
          </ng-template>
          <ng-container *ngIf="column === 'actions'">
            <div class="d-flex">
                <button mat-icon-button class="icon-more-info" (click)='showMoreInfos(element, $event)'>
                    <img ngSrc="./assets/icons/eye.svg" class="fontawesome-icon-custom icon-eye" alt="eye" height="16"
                         width="16">
                </button>
                <button mat-icon-button (click)='toggleFavorite(element, $event)'>
                    <mat-icon
                            [style.color]="(favoritesState$ | async | favorite:element) ? 'red' : ''">{{(favoritesState$ | async | favorite:element) ? 'favorite' : 'favorite_border'}}</mat-icon>
                </button>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <!-- Détail du produit -->
<!--      <ng-container matColumnDef='expandedDetail'>-->
<!--        <td mat-cell *matCellDef='let element' [attr.colspan]='columnsToDisplay.length'>-->
<!--          <div class='expanded-detail'-->
<!--               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"-->
<!--          >-->
<!--            <ng-container *ngIf='element == expandedElement'>-->
<!--              <app-product-table-expanded [productSummary]='element'></app-product-table-expanded>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </td>-->
<!--      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef='columnsToDisplay'></tr>
      <tr
        mat-row
        *matRowDef='let row; columns: columnsToDisplay'
        class='expanded-detail-element-row'
        [class.example-expanded-row]='expandedElement === row'
        (click)='expandedElement = expandedElement === row ? null : row'
      ></tr>
<!--      <tr-->
<!--        mat-row-->
<!--        *matRowDef="let row; columns: ['expandedDetail']"-->
<!--        class='expanded-detail-row'-->
<!--      ></tr>-->
    </table>
      <ng-content></ng-content>
  </div>
</ng-container>
