<h2>{{ elementSummary ? elementSummary.label : "" }}</h2>
<mat-divider class="my-3"></mat-divider>
<mat-dialog-content id="dialog-page">
  <!-- <mat-drawer-container class="menu-container" autosize>
    <mat-drawer #drawer class="menu-sidenav" mode="side">
      <mat-form-field appearance="outline">
        <mat-label>Catégories</mat-label>
        <mat-select>
          <mat-option *ngFor="let option of menuItems" [value]="option">{{
            option.id
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <ul>
        <li *ngFor="let item of menuItems" (click)="showCaracValues(item)">
          {{ item.id }}
        </li>
      </ul>
    </mat-drawer>

  </mat-drawer-container> -->

  <div class="menu-sidenav-content">
    <div *ngFor="let value of caracValues" class="row">
      <div class="col-4 label-block">
        <span>{{ value.config.Label }}</span>
      </div>
      <div class="col-8 field-block">
        <lib-app-value-view [caracIdStateValue]="value"></lib-app-value-view>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="primary"
    (click)="save(true)"
  >
    Enregistrer
  </button>
</mat-dialog-actions>
