import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {ElementSummary} from '../../../../data/models/sp-models';
import {NpCaracConfigService} from '../../../../data/services/np-carac-config.service';
import {SpUserInfoService} from '../../../../data/services/sp-user-info.service';
import {CaracConfig, NPElement, ValueOneElementHelper} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';

@Component({
  selector: 'app-product-table-expanded-detail-view',
  templateUrl: './product-table-expanded-detail-view.component.html',
  styleUrls: ['./product-table-expanded-detail-view.component.scss'],
})
export class ProductTableExpandedDetailViewComponent
  implements OnInit, OnChanges {
  @Input() currentElement: ElementSummary;
  public modificatedDate: string;
  public labelProduct: string;
  public productLength: string;
  public productPrice: string;
  public productWeight: string;
  public description = '';
  public descConfig: CaracConfig;
  public visualConfig: CaracConfig;
  private _valueHelper = new ValueOneElementHelper();
  public descStateValue: CaracIdStateValue;
  public visualStateValue: CaracIdStateValue;

  constructor(
    private _userInfoService: SpUserInfoService,
    private _caracConfigService: NpCaracConfigService
  ) {
  }

  ngOnInit(): void {
    zip(
      this._caracConfigService.getCurrentCaracConfigs(),
      this._userInfoService.getUserCustomFieldsByExtIDs([
        'CP_EXTIDDESCRIPTIF',
        'CP_EXTIDVISUEL',
      ])
    )
      .pipe(
        map(([configs, userFields]) => {

          const descFields = userFields.find(
            (field) => field.ExtID === 'CP_EXTIDDESCRIPTIF'
          );
          this.descConfig = configs.find(
            (c) => c.DicoCaracExtID === descFields.Values[0].Value
          );
          if (
            this.descConfig &&
            this.currentElement &&
            this.currentElement.element
          ) {
            this.description = this.currentElement.element.getValueTextValue(
              this.descConfig.DicoCaracExtID
            );
            this.descStateValue = this.toBlock(
              this.currentElement.element,
              this.descConfig
            );
          }
          const visualFields = userFields.find(
            (field) => field.ExtID === 'CP_EXTIDVISUEL'
          );
          this.visualConfig = configs.find(
            (c) => c.DicoCaracExtID === visualFields.Values[0].Value
          );
          this.visualConfig.Component = 'FileUploadComponent';
          this.visualStateValue = this.toBlock(
            this.currentElement.element,
            this.visualConfig
          );
          return [this.descConfig, this.visualConfig];
        })
      )
      .subscribe((response) => {
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentElement) {
      this.description = this.currentElement.element.getValueTextValue(
        'DC_R_DESCRIPTIFFOURNISSEUR'
      );
      this.labelProduct = this.currentElement.label;
      this.productWeight = this.currentElement.productWeight;
      this.productPrice = this.currentElement.productPrice;
      this.productLength = this.currentElement.productLength;
      this.modificatedDate =
        this.currentElement.modificationDate;
    }
  }

  public toBlock(element: NPElement, config: CaracConfig) {
    return {
      config: config,
      element: element,
      value: this._valueHelper.getCaracValue(element, config),
    };
  }
}
