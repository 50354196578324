import {Component, OnInit} from '@angular/core';
import {SpDicoCarac} from '@data/constants';
import {EMPTY, Observable} from 'rxjs';
import {Cards} from '@data/types';
import {ProductsFacade} from '@data/facades';
import {catchError, tap} from 'rxjs/operators';
import {ParamsFilterBuilder} from '@data/builders';
import {ParamsFilter} from '@data/models';
import {NPElementType} from '@nextpage/np-sdk-data';
import {environment} from '@env';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
    private readonly elementType: NPElementType = environment.instance.elementType;
    private paramFilterBuilder: ParamsFilterBuilder;
    isLoadingResults = false;
    cards$: Observable<Cards[]>;

    constructor(private _productsFacade: ProductsFacade, private _snackBar: MatSnackBar) {
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(10).withElementTypes([this.elementType]);
    }

    ngOnInit(): void {
        this.isLoadingResults = true;
        const paramsFilter: ParamsFilter = this.paramFilterBuilder.build();
        this._getProducts(paramsFilter);
    }

    searchByKeyword($event): void {
        this.isLoadingResults = true;
        const paramsFilter: ParamsFilter = this.paramFilterBuilder.withKeyword($event.target.value ?? '').build();
        this._getProducts(paramsFilter);
    }

    private _getProducts(paramsFilters: ParamsFilter): void {
        this.cards$ = this._productsFacade.getNProductsByCustomField(paramsFilters).pipe(
            tap(() => (this.isLoadingResults = false)),
            catchError(err => {
                this.isLoadingResults = false;
                this._snackBar.open('Une erreur c\'est produite veuillez recharger votre page !');
                return EMPTY;
            })
        );
    }
}
