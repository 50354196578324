import {Component, forwardRef, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {
    DicocaracRepository,
    ElementRepository,
    ElementWriterService,
    NPDicoCarac,
    NPElement,
    UiTranslationService
} from '@nextpage/np-sdk-data';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NpMedia, NpMediaDirectory, NpMediasTreeService, Sheet, ThemingService} from '../../services';
import {flatMap, map, tap} from 'rxjs/operators';
import jss from 'jss';
import {of} from 'rxjs';


@Component({
    selector: 'lib-np-medias-library',
    templateUrl: './np-media-library.component.html',
    styleUrls: ['./np-media-library.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NpMediaLibraryComponent implements OnInit {
    public mediaTree: NpMediaDirectory;
    public mediasToDisplay: NpMedia[] = [];
    private _mediasToDisplayCopy: NpMedia[] = [];
    private _npCurrentDirectory: NpMediaDirectory;
    private _extIDMedia = '';
    private _extIDRootMediaDirectory = '##RootDirectoryMedia';
    public medias: any[] = [];
    public currentDirectory: NPElement;
    public searchText = '';
    public dropzoneHovered = false;
    public messageEnvoi = 'Envoyer';
    public messageChargement = false;
    public filesMap = new Map<string, File>();
    public filesList: File[] = [];
    public uploadImages: UploadImage[] = [];
    public classes: Object;
    private _translations = new Map<string, string>();
    public researchFormControl = new UntypedFormControl('');
    public loading: boolean;

    constructor(private _dicoCaracReader: DicocaracRepository,
                private _elementReader: ElementRepository,
                private _elementWriter: ElementWriterService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _dialogRef: MatDialogRef<NpMediaLibraryComponent>,
                private _translateSrv: UiTranslationService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService,
                @Inject(forwardRef(() => NpMediasTreeService)) private _mediasTreeService: NpMediasTreeService) {
    }

    ngOnInit(): void {
        this.messageChargement = false;
        const override_css = this._theming.getComponentConfigStyle('NpMediasSelectionComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }

        let _extIDMedia$ = Observable.of(this._extIDRootMediaDirectory);
        if (this.data['dicoCaracExtID'] != null) {
            _extIDMedia$ = this._dicoCaracReader.getDicoCarac(this.data['dicoCaracExtID']).pipe(
                map((photoCarac: NPDicoCarac) => {
                    if (photoCarac != null && photoCarac.ElementLinkExtID != null) {
                        return photoCarac.ElementLinkExtID;
                    } else {
                        return this._extIDRootMediaDirectory;
                    }
                })
            );
        }

        _extIDMedia$.pipe(
            tap((extIDMedia: string) => {
                this._extIDMedia = extIDMedia;
                this._elementReader.getElements([this._extIDMedia], [[]]).subscribe(element => {
                    this.currentDirectory = element.get(this._extIDMedia);
                });
            }),
            flatMap((extIDMedia: string) => {
                return this._elementReader.getDescendants([extIDMedia], [[]], true);
            })
        )
            .subscribe((elements: Map<string, NPElement>) => {
                this.mediaTree = this._mediasTreeService.getMediasTree(elements.get(this._extIDMedia));
                this._mediasTreeService.displayMedias(this.mediaTree);
            });

        this._mediasTreeService.getSelectedMediaDirectory()
            .subscribe(selectedDirectory => {
                this.mediasToDisplay = selectedDirectory.medias;
                this._mediasToDisplayCopy = Object.assign(this.mediasToDisplay);
                this.currentDirectory = selectedDirectory.rootDirectory;
                this._npCurrentDirectory = selectedDirectory;
            });

        this._mediasTreeService.getLoading()
            .subscribe(_loading => {
                this.loading = _loading;
            });

        this._initResearch();
    }

    private _initResearch() {
        this.researchFormControl.valueChanges
            .subscribe((newValue: string) => {
                this.mediasToDisplay = newValue && newValue !== '' ?
                    this._mediasToDisplayCopy.filter(v => v.alt.toUpperCase().indexOf(newValue.toUpperCase()) !== -1) :
                    this._mediasToDisplayCopy;
            });
    }

    resetField() {
        this.researchFormControl.reset('');
    }

    public selectMedia(selectedMedia: NPElement) {
        this._dialogRef.close(selectedMedia);
    }

    /**
     * Gestion du survol sur la zone
     */
    handleHover($event: boolean) {
        this.dropzoneHovered = $event;
    }

    /**
     * Gestion upload fichier depuis la zone
     */
    handleDrop(file: File) {
        if (!file) {
            return;
        }
        // Ajout du fichier dans la liste s'il n'est pas déjà présent
        if (!this.filesMap.has(file.name)) {
            this.filesMap.set(file.name, file);
            this.filesList.push(file);
            this.uploadImages.push(new UploadImage(file));
        }
    }

    /**
     * Upload de l'ensemble des fichiers dans la liste
     */
    uploadAllFiles() {
        this.messageEnvoi = '_ScreenEnvoienCours';
        this.uploadImages.forEach(currentFile => {
            this.uploadFile(currentFile);
        });
    }

    /**
     * Suppression d'un fichier de la liste
     */
    removeFile(uploadImage: UploadImage, event?: Event) {
        if (event) {
            event.stopPropagation();
        }
        this.uploadImages = this.uploadImages.filter(currentFile => currentFile.file.name !== uploadImage.file.name);
    }

    /**
     * Upload d'un fichier
     * @param uploadImage
     */
    uploadFile(uploadImage: UploadImage) {
        this._elementWriter.createMedia(uploadImage.file, this.currentDirectory.ID)
            .pipe(
                flatMap(() => {
                    this.removeFile(uploadImage);
                    return this.uploadImages.length > 0 ?
                        of(false) : this._mediasTreeService.loadChildren(this._npCurrentDirectory, true);
                }))
            .subscribe(() => {
            });
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }
}

export class UploadImage {
    public file: File;
    imgURL: any;
    public message: string;

    constructor(file: File) {
        this.file = file;
        if (file.type.match(/image\/*/) !== null) {
            const reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                this.imgURL = reader.result;
            };
        }
    }
}
