<mat-dialog-content style="height: 100%">
    <div class="content-information">
        <div class="title-information">
            <h4 title="{{data.label}}">{{ data.label }}</h4>
            <div>
                <img style="cursor: pointer" (click)="print()" src="./assets/icons/print.svg"
                     class="fontawesome-icon-custom" alt="print">
                <img mat-dialog-close="true" src="./assets/icons/x-mark.svg" class="fontawesome-icon-custom"
                     alt="close">
            </div>
        </div>
        <!--        <app-loader-spinner *ngIf="loading"></app-loader-spinner>-->
        <mat-card class="mat-card-container">
            <mat-card-content>
                <div class="content-information-details">
                    <div style="margin-right: 25px">
                        <img
                                [src]='data.urlImage'
                                class="imageUrlStyle"
                                alt="Aperçu du média"
                        />
                    </div>
                    <div class="information-details">
                        <p>
                            <strong>Nom :</strong>
                            <span *ngIf="data.label">{{ data.label }}</span>
                        </p>
                        <p>
                            <strong>Taille :</strong>
                            <span *ngIf="data.mediaSizeInMo">{{ data.mediaSizeInMo }} Mo</span>
                        </p>
                        <div>
                            <strong>Téléchargement :</strong>
                            <div class="downloadUrl" *ngFor="let file of data.downloadUrl">
                                <span>
                                    <mat-chip> {{ file.label === 'original' ? 'Fichier' : 'Vignette'}}</mat-chip>  {{ file.label }}
                                </span>
                                <span>
                                    <a href="{{file.url}}">
                                        <img ngSrc="./assets/icons/download.svg"
                                             class="fontawesome-icon-custom" alt="apps"
                                             height="16" width="16">
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>
