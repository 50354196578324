import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NpInstancesFacade {

    constructor() {}

    getNextPageUrl(instanceCode: string) {}
}
