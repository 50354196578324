import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {NPElementType} from '@nextpage/np-sdk-data';
import {AdvancedResearch, StatWorkflowDTO,} from '../../../data/models/widget';

@Injectable({
  providedIn: "root",
})
export class NpWidgetStatService {
  private _urlGetStatsOfRef =
    "/api/sdk/StatReports/DefaultStats/WFsReferenceStatsByFamily/";
  private _urlGetStatsOfRefFillRateGlobal =
    "/api/sdk/StatReports/DefaultStats/getRatesStatsByFamily/ReferenceFillRate_GLOBAL/";
  private _urlGetAdvancedSearch = "/api/sdk/AdvancedSearch/GetByUser";

  constructor(private _http: HttpClient) {}

  getRateStatsOfRefs(idAdvancedResearch, extid): Observable<any> {
    return this._http.get<any>(
      this._urlGetStatsOfRefFillRateGlobal
        .concat(idAdvancedResearch.toString())
        .concat("/")
        .concat(extid)
    );
  }

  getStatsOfRefs(idAdvancedResearch, extid): Observable<StatWorkflowDTO[]> {
    return this._http.get<StatWorkflowDTO[]>(
      this._urlGetStatsOfRef
        .concat(idAdvancedResearch.toString())
        .concat("/")
        .concat(extid)
    );
  }

  getAdvancedResearchByUser(param): Observable<AdvancedResearch[]> {
    return this._http.post<AdvancedResearch[]>(
      this._urlGetAdvancedSearch,
      param
    );
  }

  getElementType() {
    return NPElementType.Family.toString()
      .concat(";")
      .concat(NPElementType.Product.toString())
      .concat(";")
      .concat(NPElementType.Reference.toString())
      .concat(";")
      .concat(NPElementType.Media.toString());
  }
}
