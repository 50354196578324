export enum RouteName {
    HOME = 'home',
    PRODUCTS = 'products',
    MEDIAS = 'medias',
    FAVORITE = 'favorite',
    IMPORT = 'import',
    ACCOUNT = 'account',
    CONSULTATION = 'consultation',
    MEDIA_LIBRARY = 'media-library',
    SUPPLIER = 'supplier',
    LOGIN = 'login',
    NO_INSTANCE_FOUND = 'no-instance-found',
    ELEMENTS_TABLE_VIEW = 'elements-viewer',
}

export enum RouteParamName {
    INSTANCE_ID = 'instanceId'
}
