import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-logo-supplier',
  templateUrl: './logo-supplier.component.html',
  styleUrls: ['./logo-supplier.component.scss']
})
export class LogoSupplierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
