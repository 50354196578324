import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CaracConfig, CaracConfigRepository} from "../../../lib";


@Injectable({
  providedIn: 'root'
})
export class CaracConfigRepositoryService {

  private _dicoConfigs$: Observable<Map<string, CaracConfig[]>>;

  constructor(private _caracConfig: CaracConfigRepository) {
  }
  public getConfigs(): Observable<Map<string, CaracConfig[]>> {
    return this._dicoConfigs$;
  }
  public getConfigsByKeyName(BlockName: string): Observable<CaracConfig[]> {
    return this._dicoConfigs$.pipe(
        map(data => data && data.get(BlockName) ? data.get(BlockName) : [])
    );
  }
}
