export enum EntityName {
    Consultation = 'consultation',
    MediaLibrary = 'media-library',
    Supplier = 'supplier',
}

export const ENTITY_NAME_MAP: Map<EntityName, { name: EntityName, label: string, description: string }> = new Map(
    [
        [EntityName.Consultation,
            {
                name: EntityName.Consultation,
                label: 'Consultation',
                description: 'Accéder au portail consultation'
            }
        ],
        [EntityName.MediaLibrary,
            {
                name: EntityName.MediaLibrary,
                label: 'Médiathèque',
                description: 'Accéder à la médiathèque'
            }
        ],
        [EntityName.Supplier,
            {
                name: EntityName.Supplier,
                label: 'Fournisseur',
                description: 'Accéder au portail fournisseur'
            }
        ],
    ]
);
