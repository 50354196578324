import { createFeatureSelector, createSelector } from '@ngrx/store';
import {authenticationFeatureKey, AuthenticationState} from "../reducers/authentication.reducer";

const selectState = createFeatureSelector<AuthenticationState>(
    authenticationFeatureKey
);
export const selectLoadingAuthentication = createSelector(
    selectState,
    (state: AuthenticationState) => state.loading
);

export const selectErrorMessageAuthentication = createSelector(
    selectState,
    (state: AuthenticationState) => state.errorMessage
);
