import {Component, Input, OnInit} from '@angular/core';
import {
  INITFILTERGROUP,
  INITSEARCHGROUP,
  LISTCOLORCHART,
  SpDicoCarac,
  STATICOPTIONSCHART,
  WidgetTypes,
} from '../../../data/constants/sp-constants';
import {
  AdvancedResearch,
  ElementReportDTO,
  StatWorkflowDefaultDTO,
  StatWorkflowDTO,
  UserWidget,
  Widget,
} from '../../../data/models/widget';
import {ChartOptions} from 'chart.js';
import {SpUserInfoService} from '../../../data/services/sp-user-info.service';
import {NpWidgetStatService} from '../services/np-widget-stat.service';
import {HelperService} from '../services/helper.service';

interface SearchGroup {
  disabled?: boolean;
  name: string;
  search: AdvancedResearch[];
}
@Component({
  selector: "app-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.scss"],
})
export class PieChartComponent implements OnInit {
  @Input() widgetItem: Widget;
  @Input() widthItem: number;
  @Input() widget: UserWidget;
  @Input() listWidget: UserWidget[] = [];
  @Input() currentUser: { ID: number };
  public node: Node;
  public chartData1 = [];
  public chartData2 = [];
  public chartType = "pie";
  public searchGroups: SearchGroup[] = INITSEARCHGROUP;
  public filtreGroups: SearchGroup[] = INITFILTERGROUP;
  public chartOptions: ChartOptions = STATICOPTIONSCHART;
  public chartColors = [
    {
      backgroundColor: LISTCOLORCHART,
    },
  ];
  public colors = LISTCOLORCHART;

  public listelementReportDTO: ElementReportDTO[];
  public listElementStats: StatWorkflowDTO[];
  public listElementsRate: StatWorkflowDefaultDTO[];
  public defaultsSelected: string;
  public defaultSearchID: number;
  public currentSearchID: any;
  public chartLabels = [];
  public chartLegend = false;
  public enumWidgetTypes = WidgetTypes;
  public extidFamily: string;

  constructor(
    private _npWidgetStat: NpWidgetStatService,
    private _helperService: HelperService,
    private _userInfoService: SpUserInfoService
  ) {}

  ngOnInit(): void {
    this._userInfoService
      .getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_FAMILLE_FOURNISSEUR)
      .subscribe((extidfamily) => {
        if (
          extidfamily &&
          extidfamily.Values &&
          extidfamily.Values.length > 0
        ) {
          this.extidFamily = extidfamily.Values[0].Value;

          this.defaultsSelected =
            this.widget.Parameters === "default"
              ? "Filtre ...."
              : this.getSearchName(this.widget.Parameters);
          this.defaultSearchID =
            this.widget.Parameters === "default"
              ? 0
              : this.getSearchId(this.widget.Parameters);
          if (this.widgetItem.WidgetType === WidgetTypes.TCR) {
            this.getFillRateGlobal(
              this.defaultsSelected,
              this.defaultSearchID,
              this.extidFamily
            );
          } else {
            this.getStats(
              this.defaultsSelected,
              this.defaultSearchID,
              this.extidFamily
            );
          }
          this.getAdvancedResearchInStats();
        }
      });
  }

  getColors(tab: StatWorkflowDTO[]): string[] {
    return tab.map((x) => x.Color);
  }

  getValuesStats(tab: any[]): number[] {
    return tab.map((x) => x.Value);
  }

  private getStats(searchName: any, idAdvanced: any, extid: string) {
    this.listElementStats = null;
    this.widget.Parameters = this._helperService.createJsonString(
      searchName,
      idAdvanced
    );
    const index = this.listWidget.indexOf(this.widget);
    this.listWidget[index] = this.widget;
    this.currentSearchID = idAdvanced;

    localStorage.setItem("listWidgets", JSON.stringify(this.listWidget));
    this._npWidgetStat.getStatsOfRefs(idAdvanced, extid).subscribe((result) => {
      this.listElementStats = result;
      this.chartData1 = [
        {
          data: this.getValuesStats(this.listElementStats),
          label: "Etat référence",
          backgroundColor: this.getColors(this.listElementStats),
        },
      ];
      if (this.listElementStats) {
        const listOfLables = this.listElementStats.map((x) => x.Label);
        this.chartLabels = listOfLables;
      }
    });
  }

  private getFillRateGlobal(
    searchName: any,
    idAdvanced: any,
    extidFamily: string
  ) {
    this.listElementsRate = null;
    this.widget.Parameters = this._helperService.createJsonString(
      searchName,
      idAdvanced
    );
    this.currentSearchID = idAdvanced;

    const index = this.listWidget.indexOf(this.widget);
    this.listWidget[index] = this.widget;
    localStorage.setItem("listWidgets", JSON.stringify(this.listWidget));
    this._npWidgetStat
      .getRateStatsOfRefs(idAdvanced, extidFamily)
      .subscribe((result) => {
        if (result.Data && result.Colors) {
          this.listElementsRate = result.Data.reverse();
          if (this.listElementsRate) {
            this.setUpChart(this.listElementsRate, result.Colors);
          }
        }
      });
  }

  private setUpChart(
    listElementsRate: StatWorkflowDefaultDTO[],
    colors: string[]
  ) {
    this.chartLabels = listElementsRate.map((x) =>
      x.Percentage.toString().concat("%")
    );
    this.chartData2 = [
      {
        data: listElementsRate.map((x) => x.Value),
        label: "Complétude",
        backgroundColor: colors,
      },
    ];
  }

  getTotal(listElementsRate: StatWorkflowDefaultDTO[]) {
    return this.getValuesStats(listElementsRate).reduce((a, b) => a + b, 0);
  }

  private getAdvancedResearchInStats() {
    if (this.widgetItem.WidgetType !== WidgetTypes.WG) {
      const currentUser = {
        ElementType: this._npWidgetStat.getElementType(),
        UserID: this.currentUser ? this.currentUser.ID : null,
      };
      this._npWidgetStat
        .getAdvancedResearchByUser(currentUser)
        .subscribe((listAdvencedSearch) => {
          this.filtreGroups[1].search = listAdvencedSearch.filter(
            (x) => x.ModifierID === this.currentUser.ID
          );
          this.filtreGroups[2].search = listAdvencedSearch.filter(
            (x) => x.ModifierID !== this.currentUser.ID
          );
        });
    }
  }

  getSearchName(parametares: string): string {
    return this._helperService.getTitleOfAdvancedSearch(parametares);
  }

  getSearchId(parametares: string): number {
    return this._helperService.getIdOfAdvancedSearch(parametares);
  }
}
