import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constantes} from '../const';
import {Observable, of} from "rxjs";
import {NpUser} from "../model";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private _baseUrlLogin = 'api/auth';
    private _baseUrlInitPassword = '/api/sdk-debug/mail/SendMailPasswordForgotten';
    private _isAuthUrl = '/api/auth/IsAuthenticate';
    private _urlGetCurrentUser = '/api/sdk/user/getCurrentUser';


    constructor(private http: HttpClient, private constantes: Constantes) {
    }

    logIn(login?: string, password?: string): Observable<string> {
        if (login === '' || password === '') {
            throw 'Le login et password sont obligatoire';
        }
        const url = this.constantes.baseUrl + this._baseUrlLogin;
        return this.http.post<string>(url, {Username: login, Password: password}, {responseType: 'json'});
    }

    getCurrentUser(): Observable<any> {
        return this.http.get<Object>(this._urlGetCurrentUser)
            .pipe(
                map((data) => {
                    if (data.hasOwnProperty('Results')) {
                        if (data['Results'] != null) {
                            return <NpUser[]>data['Results'].Users;
                        }
                    }
                }),
                catchError(error => {
                    console.log(error);
                    return of(true);
                }),
            );
    }


    initPassword(userMail: string, langID = 2) {
        return this.http.post<any>(this.constantes.baseUrl + this._baseUrlInitPassword, {
            MailTo: userMail,
            AbsoluteRootUrl: this.constantes.baseUrl,
            LangID: langID
        });
    }

  isAuthenticated(currentToken: string) {
    return this.http.post<IsAuthenticatedModel>(this.constantes.baseUrl + this._isAuthUrl, {ApiToken: currentToken})
        .pipe(
            map(response => {
              if (response) {
                return response.IsAuthenticated;
              }
              return false;
            })
        );
  }
}
interface IsAuthenticatedModel {
  IsAuthenticated: boolean;
  ApiToken: string;
}

