import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';

import * as _ from 'lodash';
import {EMPTY, Observable, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '@env';
import {NPElementType, NpUser, NpwFilterLogicalOperator, NpwFilterOperator} from '@nextpage/np-sdk-data';
import {SpDicoCarac, TABLE_HEADER_LABELS} from '@data/constants';
import {CharsBuilder, ItemBuilder, ParamsFilterBuilder, SelectedDicoBuilder} from '@data/builders';
import {CharTemplateDto, ParamsFilter, ProductSummary} from '@data/models';
import {ProductsFacade} from '@data/facades';
import {Chars, ChoiceCriteria, Item, SelectedDico} from '@data/types';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExportExcelService} from '../../data/services/export-excel.service';
import {SpUserInfoService} from '@data/services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {ExportExcelSummary} from '../../data/models/export-models';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {RouteName} from "../../data/constants/route.constants";
import {RouteResolverService} from "../../data/services/routes/route-resolver.service";
import {NpInstanceService} from "../../data/services/np-instance.service";

@Component({
    selector: 'app-products-page',
    templateUrl: './products-page.component.html',
    styleUrls: ['./products-page.component.scss'],
})
export class ProductsPageComponent implements OnInit, OnDestroy {
    private readonly elementType: NPElementType = environment.instance.elementType;
    private paramFilterBuilder: ParamsFilterBuilder;
    public currentUser: NpUser;
    columnsToDisplay: string[];
    headerLabels = TABLE_HEADER_LABELS;
    productsSummary$: Observable<MatTableDataSource<ProductSummary>>;
    totalRows: number;
    pageSize: number;
    isLoadingResults = false;
    typeOfProducts$: Observable<CharTemplateDto[]>;
    filtersCriteriaList: ChoiceCriteria[];
    filtersCriteriaSub: Subscription;
    dataExport: Subscription;
    isLoadingSelect = true;
    formFilter: FormGroup;

    private _fileName = '';
    public isSaving = false;
    public publishError: boolean;
    public errorName: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private _snackBar: MatSnackBar,
                private productsFacade: ProductsFacade,
                private fb: FormBuilder,
                private _exportExcelService: ExportExcelService,
                private _userInfoService: SpUserInfoService,
                private _routeResolverService: RouteResolverService,
                public dialog: MatDialog) {
        this.columnsToDisplay = ['overview', 'label', 'modificationDate', 'actions'];
        this.pageSize = 10;
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(this.pageSize).withElementTypes([this.elementType]);
        this.formFilter = this.fb.group({
            formProductType: ['']
        });
    }

    ngOnInit(): void {
        this.isLoadingResults = true;
        this.typeOfProducts$ = this._loadTypeProduct();
        const paramsFilter = this.paramFilterBuilder.build();
        this.productsSummary$ = this._loadProduct(paramsFilter);
        this._userInfoService.getCurrentUser().subscribe((response) => {
            this.currentUser = response;
        });
    }

    ngOnDestroy(): void {
        if (this.filtersCriteriaSub) {
            this.filtersCriteriaSub.unsubscribe();
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogValidationExport, {
            width: '400px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.exportAll(this.productsSummary$);
            }
        });
    }

    searchByKeyword($event): void {
        this.isLoadingResults = true;
        const paramsFilter: ParamsFilter = this.paramFilterBuilder.withKeyword($event.target.value ?? '').build();
        this.productsSummary$ = this._loadProduct(paramsFilter);
    }

    handlePageEvent($event: PageEvent): void {
        const paramsFilter = this.paramFilterBuilder
            .withPage($event.pageIndex + 1)
            .withNumberOfElementByPage($event.pageSize)
            .build();
        this.isLoadingResults = true;
        this.productsSummary$ = this._loadProduct(paramsFilter);
    }

    filterByTypeProduct($event: MatSelectChange): void {
        const filtersCriteriaList = $event.value.CharTemplateChoiceCriterias;
        if (filtersCriteriaList) {
            this._buildForm(filtersCriteriaList, $event.value);
            this.filtersCriteriaList = filtersCriteriaList;
            if (filtersCriteriaList && filtersCriteriaList.length > 0) {
                this.isLoadingSelect = true;
                this.filtersCriteriaSub = this.productsFacade
                    .completeChoiceCriteriaWithFacets(filtersCriteriaList, SpDicoCarac.CP_EXT_ID_PRODUIT, $event.value.ID)
                    .subscribe((criteriaChoice: ChoiceCriteria[]) => {
                        this.isLoadingSelect = false;
                        this.filtersCriteriaList = criteriaChoice;
                    });
            }
            const paramsFilter = this.paramFilterBuilder.withProductTypeId($event.value.ID).withElementTypes([this.elementType]).build();
            this.productsSummary$ = this._loadProduct(paramsFilter);
            this.isLoadingResults = true;
        } else if ($event.value === 'reset') {
            this.clearAllFilters();
        }
    }

    clearAllFilters() {
        this.isLoadingSelect = false;
        this.isLoadingResults = true;
        this.filtersCriteriaList = [];
        const paramsFilter = this.paramFilterBuilder.withProductTypeId(null).withChars({} as Chars).withElementTypes([this.elementType]).build();
        this.productsSummary$ = this._loadProduct(paramsFilter);
        if (this.formFilter.get('formProductType')?.value !== 'reset') {
            this.formFilter.reset();
        }
    }

    /**
     * TODO: Implétenter la recherche multi opérateurs (et/ou) quand l'évolution sera disponible dans NEXTPAGE
     */
    filterData(): void {
        const items = this._buildItems();
        const chars: Chars = new CharsBuilder().withLogicalOperator(NpwFilterLogicalOperator.OR.toLowerCase()).withItems(items).build();
        const paramsFilter = this.paramFilterBuilder
            .withElementTypes([environment.instance.elementType])
            .withChars(chars)
            .build();
        this.isLoadingResults = true;
        this.productsSummary$ = this._loadProduct(paramsFilter);
    }

    private _buildForm(filtersCriteriaList: ChoiceCriteria[], charTemplateDto: CharTemplateDto): void {
        this.formFilter = this.fb.group({formProductType: this.fb.control(charTemplateDto)});
        filtersCriteriaList.forEach(criteria => this.formFilter.addControl(criteria.DicoCaracID.toString(), this.fb.control([])));
    }

    private _loadTypeProduct(): Observable<CharTemplateDto[]> {
        return this.productsFacade.listFilters();
    }

    private _loadProduct(paramsFilter: ParamsFilter): Observable<MatTableDataSource<ProductSummary>> {
        return this.productsFacade.listProductWithPagination(paramsFilter).pipe(
            map(productsSummaryWithTotalRow => {
                const dataSource = new MatTableDataSource<ProductSummary>(productsSummaryWithTotalRow.productSummaryList);
                this.isLoadingResults = false;
                this.totalRows = productsSummaryWithTotalRow.totalsRows;
                return dataSource;
            }),
            catchError(err => {
                this.isLoadingResults = false;
                this._snackBar.open('Une erreur c\'est produite veuillez recharger votre page !');
                return EMPTY;
            })
        );
    }

    private _buildItems(): Item[] {
        const items: Item[] = [];
        _.values(this.formFilter.value)
            .filter(value => 'length' in value)
            .reduce((acc, value) => [...acc, ...value])
            .map(fieldFilters => {
                const selectedDico: SelectedDico = new SelectedDicoBuilder().withID(fieldFilters.id).withTypeCode(fieldFilters.typeCode).build();
                items.push(new ItemBuilder().withOperatorValue(NpwFilterOperator.Equal).withValue(fieldFilters.value).withSelectedDico(selectedDico).build());
            });
        return items;
    }

    /**
     * PROVISOIRE :  A SUPPRIMER QUAND LA RECHERCHE MULTI OPERATEURS SERA DISPONIBLE DANS NEXTPAGE
     */
    private _buildItemsWithMultiValueOperators(): ItemMap[] {
        const itemMaps: ItemMap[] = [];
        _.values(this.formFilter.value)
            .filter(value => 'length' in value)
            .reduce((acc, value) => [...acc, ...value])
            .map(fieldFilters => {
                const selectedDico: SelectedDico =
                    new SelectedDicoBuilder().withID(fieldFilters.id).withTypeCode(fieldFilters.typeCode).withDicoCaracExtId(fieldFilters.dicoCaracExtId).build();
                const item = new ItemBuilder().withOperatorValue('=').withValue(fieldFilters.value).withSelectedDico(selectedDico).build();
                let itemMap = itemMaps.find(currentItemMap => currentItemMap.dicoId === fieldFilters.id);
                if (!itemMap) {
                    itemMap = new ItemMap();
                    itemMap.dicoId = fieldFilters.id;
                    itemMap.items.push(item);
                    itemMaps.push(itemMap);
                } else {
                    itemMap.items.push(item);
                }
            });
        return itemMaps;
    }

    /**
     * PROVISOIRE :  A SUPPRIMER QUAND LA RECHERCHE MULTI OPERATEURS SERA DISPONIBLE DANS NEXTPAGE
     */
    filterDataWithMultipleValueOperators() {
        const itemMaps = this._buildItemsWithMultiValueOperators();
        if (itemMaps.length === 0) {
            this.filterData();
        } else {
            this.productsSummary$ = this.searchByItems(itemMaps[0].items)
                .pipe(
                    map(response => {
                        response.data = itemMaps.reduce((accumulator, currentValue) => {
                            accumulator = accumulator.filter(data => currentValue.items.some(item => data.element.getValueTextValue(item.SelectedDico.DicoCaracExtId) === item.Value));
                            return accumulator;
                        }, Object.assign([], response.data));
                        return response;
                    })
                );
        }
    }

    /**
     * PROVISOIRE :  A SUPPRIMER QUAND LA RECHERCHE MULTI OPERATEURS SERA DISPONIBLE DANS NEXTPAGE
     */
    searchByItems(items: Item[]) {
        const chars: Chars = new CharsBuilder().withLogicalOperator(NpwFilterLogicalOperator.OR.toLowerCase()).withItems(items).build();
        const paramsFilter = this.paramFilterBuilder
            .withElementTypes([NPElementType.Reference, NPElementType.Product])
            .withChars(chars)
            .withPage(1)
            .build();
        this.isLoadingResults = true;
        this.paginator?.firstPage();
        return this._loadProduct(paramsFilter);
    }

    private exportAll(data) {
        this.dataExport = data.subscribe((result: MatTableDataSource<ProductSummary>) => {
            if (result.filteredData) {
                const productsID = data.filteredData.map(productSummary => {
                    return productSummary.element.ID;
                });
                this._executeExportExcel(productsID);
            }
        });
    }

    private _startSaving() {
        this.isSaving = true;
    }

    private _stopSaving() {
        this.isSaving = false;
    }

    private _executeExportExcel(productsID) {
        this._startSaving();
        const exportExcelSummary: ExportExcelSummary = {
            fileName: this._fileName.replace(/ /g, '_'),
            userEmail: this.currentUser.Email,
            userID: this.currentUser.ID,
            productsId: productsID,
            profileExportName: this.productsFacade.getProfileExport()
        };
        this._exportExcelService.sendObject(exportExcelSummary)
            .subscribe(() => {
                this._stopSaving();
                this._snackBar.open('Un mail contenant le lien de téléchargement du fichier vous a été envoyé !');
                if (this.dataExport) {
                    this.dataExport.unsubscribe();
                }
            }, error => {
                this._stopSaving();
                this.publishError = true;
                this.errorName = error;
            });
    }

    goToImportPage() {
        return this._routeResolverService.navigateToCurrentEntityRoute(RouteName.IMPORT);
    }
}


/**
 * PROVISOIRE :  A SUPPRIMER QUAND LA RECHERCHE MULTI OPERATEURS SERA DISPONIBLE DANS NEXTPAGE
 */
class ItemMap {
    dicoId: number;
    items: Item[] = [];
}

@Component({
    selector: 'dialog-validation-export',
    templateUrl: 'dialog-validation-export.html',
})
export class DialogValidationExport {
    constructor(public dialogRef: MatDialogRef<DialogValidationExport>) {
    }
}

