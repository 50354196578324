<mat-dialog-content style="height: 100%">
    <div class="content-information">
        <div class="title-information">
            <h4>{{ data.label }}</h4>
            <div>
                <img style="cursor: pointer" (click)="print()" src="./assets/icons/print.svg"
                     class="fontawesome-icon-custom" alt="print">
                <img mat-dialog-close="true" src="./assets/icons/x-mark.svg" class="fontawesome-icon-custom"
                     alt="close">
            </div>
        </div>
        <app-loader-spinner *ngIf="loading"></app-loader-spinner>
        <mat-card class="mat-card-container">
            <mat-card-content>
                <div class="content-information-details">
                    <div style="margin-right: 25px">
                        <img
                                [src]='data.urlImage'
                                class="imageUrlStyle"
                                alt="Aperçu du média"
                        />
                    </div>
                    <div class="information-details">
                        <div class="pField" *ngFor="let field of fields">
                            <div class="strongField">{{field.label}} :</div>
                            <div class="mb-2" *ngIf="field.value && !field.pdf">{{ field.value }}</div>
                            <a *ngIf="field.pdf" [href]="field.pdf">{{ field.value }}</a>
                            {{ field.unit}}
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-content *ngIf="linkedElements && linkedElements.length > 0">
                <h5>Produits liés</h5>
                <div class="cross-selling-container">
                    <div *ngFor="let link of linkedElements.slice(0, 5)">
                        <div class="cross-selling-label"><strong>
                            {{ link.label }}
                        </strong></div>
                        <img
                                [src]='link.urlImage'
                                class="imageUrlStyle"
                                alt="Aperçu du média"
                        />
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>
