import { Pipe, PipeTransform } from '@angular/core';

import { Favorite } from '@data/models';
import { Cards } from '@data/types';

@Pipe({
  name: 'favorite',
})
export class FavoritePipe implements PipeTransform {
  transform(favoriteList: Favorite[], elementSummery: Cards): boolean {
    const favoriteExtIDList = favoriteList.map(favorite => favorite.extID);
    if (favoriteExtIDList.includes(elementSummery.element.ExtID)) {
      elementSummery.isFavoriteElement = true;
      return true;
    } else {
      return false;
    }
  }
}
