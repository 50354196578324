import {NpInstanceConfiguration} from '../models/np-instance-model';
import {NPElementType} from '../../../lib';
import {EntityName} from '../../../lib/data/model/portal';
import {RouteName} from './route.constants';

export const NP_INSTANCE_CONFIG: Map<string, NpInstanceConfiguration> = new Map([
    ['fermob', {
        instanceId: 'fermob',
        name: 'Fermob',
        npBaseUrl: 'https://projet-fermob375.nextpage.fr/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-fermob.jpg',
        portalType: EntityName.Consultation,
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
    ['ctop', {
        instanceId: 'ctop',
        name: 'Ctop',
        npBaseUrl: 'https://demo-ctop.nextpage.fr/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-ctop.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
    ['caib', {
        instanceId: 'caib',
        name: 'Caib',
        npBaseUrl: 'https://preprod-caib.nextpage.fr/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Reference,
        bgImageName: '',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
    ['boschatlaveix', {
        instanceId: 'boschatlaveix',
        name: 'Boschatlaveix',
        npBaseUrl: 'https://demo-boschatlaveix.nextpage.fr/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Reference,
        bgImageName: 'login-background-boschatlaveix.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
    ['sportpulsion', {
        instanceId: 'sportpulsion',
        name: 'Sportpulsion',
        npBaseUrl: 'https://demo-sportpulsion.nextpage.fr/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Reference,
        bgImageName: 'login-background-sp.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
    ['gravotech', {
        instanceId: 'gravotech',
        name: 'Gravotech',
        npBaseUrl: 'https://preprod-gravotech.nextpage.fr/',
        defaultEntityRoute: RouteName.MEDIA_LIBRARY,
        elementType: NPElementType.Reference,
        bgImageName: '',
        allowedEntities: [EntityName.MediaLibrary]
    }],
    ['testa375', {
        instanceId: 'testa375',
        name: 'Testa375',
        npBaseUrl: 'https://projet-testa375.nextpage.fr/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'testa375.jpg',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
    ['local', {
        instanceId: 'local',
        name: 'local',
        npBaseUrl: 'https://localhost:44303/',
        defaultEntityRoute: RouteName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'testa375.jpg',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier]
    }],
]);
