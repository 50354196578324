<div class="expanded-detail-container">
  <div class="expanded-detail-img-block">
    <div class="expanded-detail-img-content">
      <lib-app-value-view
        [caracIdStateValue]="visualStateValue"
        [element]="currentElement.element"
      ></lib-app-value-view>
    </div>
  </div>
  <div>
<!--    <div class="description-block">-->
<!--      Label: {{ labelProduct }}-->
<!--    </div>-->
    <div class="description-attribution description-block">
      <strong>Description:</strong> {{ description }}
    </div>
    <div *ngIf="productPrice" class="description-attribution description-block">
      <strong>Prix:</strong> {{ productPrice }} €
    </div>
    <div *ngIf="productWeight" class="description-attribution description-block">
      <strong>Poids:</strong> {{ productWeight }} kg
    </div>
    <div *ngIf="productLength" class="description-attribution description-block">
      <strong>Longueur:</strong> {{ productLength }} m
    </div>
<!--    <div class="description-attribution description-block">-->
<!--     Date de création: {{ modificatedDate }}-->
<!--    </div>-->
  </div>
</div>
