import {Injectable} from '@angular/core';
import {environment} from '@env';
import {NpInstanceConfiguration} from '../models/np-instance-model';
import {Constantes} from '@nextpage/np-sdk-data';
import {NpEntity} from '../models/np-entity.models';
import {RouteName} from '../constants/route.constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/internal/operators';
import {EntityName} from "../../../lib/data/model/portal";
import {map} from "rxjs/operators";

interface InstancePage {
    page: RouteName;
}

@Injectable({
    providedIn: 'root'
})
export class NpInstanceService {

    private _instanceId: string;
    private _instance: NpInstanceConfiguration;
    private _currentEntity: NpEntity;
    private _instancePage: InstancePage;
    private _instancePage$ = new BehaviorSubject<InstancePage>(null);
    private _instance$ = new BehaviorSubject<NpInstanceConfiguration>(null);

    constructor(private _constants: Constantes) {
    }

    set instanceId(value: string) {
        this._instanceId = value;
    }

    get instanceId(): string {
        return this._instanceId;
    }

    get instance(): NpInstanceConfiguration {
        return this._instance;
    }

    set instance(value: NpInstanceConfiguration) {
        this._instance = value;
        environment.instance = this._instance;
        this._constants.baseUrl = this._instance.npBaseUrl;
        this._instance$.next(this._instance);
    }

    getInstance$(): Observable<NpInstanceConfiguration> {
        return this._instance$.asObservable();
    }

    getBgImageInstance() {
        return this.getInstance$().pipe(
            map(instance => (
                {'background-image': `url("../assets/images/${instance?.bgImageName || "bg-nextpage-default.jpg"}")`}
            ))
        )
    }

    get currentEntity(): NpEntity {
        return this._currentEntity;
    }

    isSupplierPage() {
        return this.currentEntity.name === EntityName.Supplier;
    }

    set currentEntity(value: NpEntity) {
        this._currentEntity = value;
    }

    /**
     * Check if current entity is allowed
     * @param currentEntity
     */
    hasAllowedEntity(currentEntity: string) {
        return this.instance.allowedEntities.some(allowEntity => allowEntity === currentEntity);
    }

    setCurrentInstancePage(currentPage: RouteName) {
        this._instancePage = {...this._instancePage, page: currentPage};
        this._instancePage$.next(this._instancePage);
    }

    getCurrentInstancePage() {
        return this._instancePage$.asObservable()
            .pipe(
                filter(instancePage => !!instancePage)
            );
    }
}
