<div class="user-card">
  <h1>Fiche utilisateur</h1>
  <div *ngIf="currentUser">
    <div class="card-content">
      <mat-form-field appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>Nom</mat-label>
        <input disabled matInput value="{{ currentUser.LastName }}" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>Prénom</mat-label>
        <input disabled matInput value="{{ currentUser.FirstName }}" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field disabled appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>Email</mat-label>
        <input disabled type="email" matInput value="{{ currentUser.Email }}" />
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-8 p-2">
        <mat-label>Mot de passe</mat-label>
        <input type="password" matInput value="" />
        <mat-icon matSuffix>password</mat-icon>
      </mat-form-field>
      <div>
        <button mat-raised-button color="primary">Sauvegarder</button>
      </div>
    </div>
  </div>
</div>
