import {Component, OnInit} from '@angular/core';
import {FavoriteActions} from "@store/action";
import {Store} from "@ngrx/store";

@Component({
  selector: "app-account-page",
  templateUrl: "./account-page.component.html",
  styleUrls: ["./account-page.component.scss"],
})
export class AccountPageComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(FavoriteActions.loadFavorite())
  }
}
