<div class='row'>
    <div>
        <div>
            <h3>Produits</h3>
        </div>
        <mat-divider class='my-3'></mat-divider>
        <form [formGroup]='formFilter'>
            <div class='mat-form-field-text'>
                <mat-form-field appearance='outline' class="field-search-custom">
                    <mat-label>Rechercher</mat-label>
                    <input type='text' matInput (keyup.enter)='searchByKeyword($event)'/>
                    <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search">
                </mat-form-field>
                <div>
                    <button type="button" mat-flat-button color='primary' (click)="openDialog()">
                        <i class="pr-1 pi pi-download"></i>
                        Tout exporter
                    </button>
                </div>
            </div>
            <div class='list-filters'>
                <mat-form-field appearance='outline' class='filter-form-field'>
                    <mat-label>Type de produit</mat-label>
                    <mat-select formControlName="formProductType" (selectionChange)='filterByTypeProduct($event)'>
                        <mat-option value='reset'>Tous</mat-option>
                        <mat-option *ngFor='let typeProduct of (typeOfProducts$ | async)' [value]='typeProduct'>
                            {{ typeProduct.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf='filtersCriteriaList && filtersCriteriaList.length > 0'>
                    <mat-form-field appearance='outline' class='filter-form-field'
                                    *ngFor='let filtersCriteria of filtersCriteriaList' style='margin-left: 10px;'>
                        <mat-label *ngIf='isLoadingSelect === false'>{{filtersCriteria.label}}</mat-label>
                        <mat-select multiple [placeholder]="isLoadingSelect ? 'Chargement en cours...': ''"
                                    [formControlName]='filtersCriteria.DicoCaracID'>
                            <mat-option *ngFor='let facet of filtersCriteria.facets'
                                        [value]='{id: filtersCriteria.DicoCaracID,
                                        value: facet.Value,
                                        typeCode: filtersCriteria.typeCode,
                                        dicoCaracExtId: filtersCriteria.dicoCaracExtId}'>
                                {{facet.Value}} ({{facet.Count}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button (click)='filterDataWithMultipleValueOperators()' mat-stroked-button
                            style='margin-left: 10px'>
                        <mat-icon>filter_list</mat-icon>
                        Filtrer
                    </button>
                    <button (click)="clearAllFilters()" mat-stroked-button style='margin-left: 10px'>
                        <mat-icon>clear</mat-icon>
                        Réinitialiser
                    </button>
                </ng-container>
            </div>
        </form>
        <div class='mat-elevation-z2' style="overflow-y: auto;">
            <app-loader-spinner *ngIf='isLoadingResults'></app-loader-spinner>
            <app-product-table
                    [dataSource]='productsSummary$ | async'
                    [columnsToDisplay]='columnsToDisplay'
                    [headerLabels]='headerLabels'>
                <mat-paginator [length]='totalRows' showFirstLastButtons
                               [pageSize]='pageSize'
                               [pageSizeOptions]='[5, 10, 25, 50, 100, 200, 500]'
                               (page)='handlePageEvent($event)'
                               #paginator>
                </mat-paginator>
            </app-product-table>
        </div>
    </div>
</div>
