/**
 * Classe permettant de définir la configuration d'un composant d'affichage (IUpdatableComponent)
 */
import {Access} from './access';
import {NPDicoCarac} from './np-dico-carac';


export class CaracConfig {
  Bloc?: string;
  BlocInfo?: string;
  Component?: string;
  ComponentRouting?: any;
  DicoCaracExtIDLabel?: string;
  Specific?: any;
  /**
   * Identifiant de la caractéristique à afficher
   * (permet de retrouver le caracValeur correspondant à l'élément)
   */
  DicoCaracExtID?: string;

  /**
   * Etat forcé : pour indiquer que le composant doit être en RW ou R indépendamment des droits de l'utilisateur sur la valeur
   */
  ForcedState?: Access = Access.LECTURESEULE;

  /**
   * Chemin permettant d'accéder à l'élément porteur de la valeur
   * Il s'agit d'une liste de DicoCaracExtID, chacun d eux porte sur une caractéristique de type lien
   */
  Links?: string[];
  /**
   * Permet de stocker la configuration pour charger des données
   * => utile éventuellement pour les liens (pour gérer les données à charger)
   * => pour l'instant, uniquement utile pour le chargement initial
   */
  LinksToLoad?: string[][];

  LoadData?: string[];

  /**
   * DicoCarac
   */
  DicoCarac?: NPDicoCarac;

  /**
   * Name
   */
  Label?: string;

  /**
   * Tooltip
   */
  Tooltip?: string;
  ShowCondition?: string;
  Configuration?: string;
  ConfigurationObject?: CaracConfig[];
  /**
   *
   */
  PrintLabel ? = true;

  Columns ? =  '';

  constructor() {
    this.ForcedState = Access.LECTURESEULE;
    this.Links = [];
  }


}
