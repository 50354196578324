import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SpUserFields} from '../../../data/constants/sp-user-fields';
import {CaracConfig} from '@nextpage/np-sdk-data';
import {SpValueHelper} from '../../../data/helpers/sp-value-helper';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {ElementSummary} from '../../../data/models/sp-models';
import {SpUserInfoService} from '../../../data/services/sp-user-info.service';
import {SpElementWriterService} from '../../../data/services/sp-element-writer.service';
import {SpLoaderService} from '../services/sp-loader.service';

@Component({
  selector: 'app-dialog-element-writer',
  templateUrl: './dialog-element-writer.component.html',
  styleUrls: ['./dialog-element-writer.component.scss'],
})
export class DialogElementWriterComponent implements OnInit {
  public menuItems: MenuItem[] = [];
  public openMenu = true;
  public elementSummary: ElementSummary;
  public caracValues: CaracIdStateValue[] = [];

  constructor(
    public _dialogRef: MatDialogRef<DialogElementWriterComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ElementDialogData,
    private _userInfoService: SpUserInfoService,
    private _elementWriterService: SpElementWriterService,
    private _loaderService: SpLoaderService
  ) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.elementSummary = this.data.elementSummary;
    }
    this._userInfoService
      .getConfigByUserField(SpUserFields.CP_LISTE_EXTID_CARAC)
      .subscribe((configs) => {
        if (configs && configs.length > 0) {
          configs.forEach((config) => {
            this._addToTree(config);
          });
        }
        // Affichage du premier onglet
        if (this.menuItems && this.menuItems.length > 0) {
          this.showCaracValues(this.menuItems[0]);
        }
      });
  }

  private _addToTree(config: CaracConfig) {
    let item = this.menuItems.find(
      (x) => x.id === config.DicoCarac.LibelleDossier
    );
    if (item) {
      item.add(config);
    } else {
      item = new MenuItem();
      item.id = config.DicoCarac.LibelleDossier;
      item.add(config);
      this.menuItems.push(item);
    }
  }

  showCaracValues(item: MenuItem) {
    if (this.elementSummary) {
      this.caracValues = item.configs.map((config) =>
        SpValueHelper.toBlock(this.elementSummary.element, config)
      );
    }
  }

  save(close = false) {
    if (this._elementWriterService.hasModifications()) {
      this._loaderService.openPreloader();
      this._elementWriterService.pushToNextPage().subscribe(
        (response) => {
          this._loaderService.close();
          if (close) {
            this._dialogRef.close();
          }
        },
        (error) => {
          // this._loaderService.openInfoDialog(
          //   error
          //     ? error.toString()
          //     : 'Erreur lors de la sauvegarde des données dans NP.'
          // );
          this._dialogRef.close();
        }
      );
    } else {
      this._loaderService.openSnackBar('Aucune modification à archiver !');
    }
  }
}

class MenuItem {
  id: string;
  configs: CaracConfig[] = [];

  add(config: CaracConfig) {
    if (config.DicoCarac.LibelleDossier === this.id) {
      this.configs.push(config);
    }
  }
}

class ElementDialogData {
  elementSummary: ElementSummary;
}
