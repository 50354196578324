import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {Favorite} from '@data/models';
export const FavoriteActions = createActionGroup({
    source: 'favorite',
    events: {
        'load favorite': emptyProps(),
        'load favorite success': props<{ favorites: Favorite[] }>(),
        'remove from favorite': props<{ favorites: Favorite }>(),
        'toggle favorite': props<{ favorites: Favorite }>(),
    },
});
