<div class="wrapper" *ngIf="filteredCard.length > 0; else noCards">
  <div class="card" *ngFor="let card of filteredCard">
    <button mat-icon-button (click)='toggleFavorite(card, $event)'>
      <mat-icon [style.color]="(favoritesState$ | async | favorite:card) ? 'red' : ''">{{(favoritesState$ | async | favorite:card) ? 'favorite' : 'favorite_border'}}</mat-icon>
    </button>
    <div class="card__body">
      <div class="card__body-cover">
        <img class="card__body-cover-image" [src]="card.urlImage" alt="Product view" />
      </div>
      <div class="card__body-header">
        <p class="card__body-header-title" title="{{ card.label }}">{{ card.label | truncate: 50 }}</p>
      </div>
      <div>
        <button
                (click)="moreInfo(card); $event.stopPropagation()"
                class="button-dialog"
                mat-flat-button
        >
          Plus d'informations
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #noCards>
  <div class="noCardsAvailable">Aucun résultat !</div>
</ng-template>
