import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {ParamsFilter} from '../models';
import {DicoCarac, FileReaderService, NPElement, NPElementType, SearchRepository} from '../../../lib';
import {map, switchMap} from 'rxjs/operators';
import {
    AdvancedSearchDTOBuilder, AdvSearchArgsBuilder,
    CharsBuilder,
    FamilyBuilder,
    FiltersBuilder,
    ParamsFilterBuilder,
    SortFieldBuilder
} from '@data/builders';
import {AdvSearchArgs, Cards} from "@data/types";
import {ElementLabels, SearchDisplayFieldType} from "@data/constants";
import {SpUserInfoService, TableHandlerService} from "@data/services";
import {ThumbsSizeService} from "../services/thumbs-size.service";

const CARAC_EXT_IDS = ['##MediaFile', '##MediaLabel', 'DC_MEDIA_METADATA_Size', 'DTOSystemLastModifiedDate'];

@Injectable({
    providedIn: 'root'
})
export class MediaFacade {

    private paramFilterBuilder: ParamsFilterBuilder;

    constructor(private _searchRepository: SearchRepository, private _fileReaderService: FileReaderService, private _thumbsSizeService: ThumbsSizeService, private  _userInfoService:SpUserInfoService) {
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(10).withElementTypes([NPElementType.Media]);
    }

    public getNMediasByCustomField() {
        this.paramFilterBuilder.withChars(new CharsBuilder()
            .withLogicalOperator('or')
            .withItems([])
            .build()
        );
        const paramsFilter: ParamsFilter = this.paramFilterBuilder.build();

        return this._userInfoService.getMediasExtId().pipe(
            switchMap(result => {
                if (result && result.Values && result.Values.length > 0) {
                    return this.searchMediasByParamsFilter(parseInt(result.Values[0].Value), paramsFilter, CARAC_EXT_IDS);
                } else {
                    console.error('ID medias not found, please add or fill CP_ID_MEDIAS')
                    return throwError('ID medias not found, please add or fill CP_ID_MEDIAS' );
                }
            })
        )

    }

    public searchMediasByParamsFilter(folderId: number, paramsFilters: ParamsFilter, dcExtIds: string[], paths: string[][] = [[]]) {
        if (paramsFilters) {
            const config = this.generateAdvSearchArgs(folderId, paramsFilters, dcExtIds, paths);
            return this._searchRepository.Search(config)
                .pipe(
                    map(nPSearchResult => {
                        return nPSearchResult.elements.map(element => this.npElementToCards(element));
                    }),
                    switchMap(cards => {
                        return this._thumbsSizeService.getAllowedThumbsSize()
                            .pipe(
                                map(availableSizes => {
                                    return cards.map(card => {
                                        return {
                                            ...card,
                                            urlImage: this.getImageUrl(card.imageUrl),
                                            downloadUrl: [
                                                {label: 'original', url: this.getFilePathToDownload(card.imageUrl)},
                                                ...availableSizes.map(size => ({
                                                    label: size,
                                                    url: this.getFilePathToDownloadCustom(card.imageUrl, size)
                                                }))
                                            ]
                                        };
                                    })
                                })
                            )
                    })
                );

        } else {
            return throwError(
                'Filter params not found.'
            );
        }
    }

    private generateAdvSearchArgs(familyId: number, paramsFilter: ParamsFilter, dcExtIds: string[], paths: string[][] = [[]]): AdvSearchArgs {
        const sortField = new SortFieldBuilder()
            .withFieldType(SearchDisplayFieldType.ModificationDate).build();
        const family = new FamilyBuilder().withID(familyId).build();
        const filters = new FiltersBuilder()
            .withFamily(family)
            .withKeywords(paramsFilter.keyword)
            .withProductsTypeID(paramsFilter.productTypeId)
            .withElementTypes(paramsFilter.elementTypeList)
            .withChars(paramsFilter.chars)
            .build();
        const advancedSearchDTO = new AdvancedSearchDTOBuilder()
            .withSortFields([sortField])
            .withFilters(filters).build();
        return new AdvSearchArgsBuilder()
            .withPageSize(paramsFilter.numberOfElementByPage)
            .withCurrentPage(paramsFilter.page)
            .withPaths(paths)
            .withFacets(paramsFilter.facetsList)
            .withConfig(advancedSearchDTO)
            .withDCExtIDs(dcExtIds)
            .build();
    }

    private npElementToCards(element: NPElement): Cards {
        const lastModifiedInfos = TableHandlerService.getLastModifiedInfos(element);
        return {
            label: element.getValueTextValue(ElementLabels[element.ElementType]),
            modificationDate: TableHandlerService.toDate(lastModifiedInfos),
            actions: '', // Permet de créer la colonne "Actions" dans le tableau pour afficher les boutons
            element: element,
            imageUrl: element.getValueTextValue(DicoCarac.MEDIA_FILE)
        };
    }

    public getImageUrl(image: string): string {
        return this._fileReaderService.toFilePath(image);
    }
    public getFilePathToDownload(image: string): string {
        return this._fileReaderService.toFilePathToDownload(image);
    }
    public getFilePathToDownloadCustom(image: string, size: string) {
        return this._fileReaderService.toFilePathToDownloadCustomSize(image, size);
    }
}
