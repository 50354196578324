import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {Store} from '@ngrx/store';

import {Observable} from 'rxjs';

import {ElementSummary, Favorite, ProductSummary} from 'src/app/data/models';
import {ProductsFacade} from 'src/app/data/facades';
import {TABLE_HEADER_LABELS} from 'src/app/data/constants';
import {SpUserInfoService} from 'src/app/data/services';
import {FileReaderService} from 'src/lib';
import {selectFavoritesList} from 'src/app/store/selector';
import {FavoriteActions} from 'src/app/store/actions';
import {
    DialogProductSheetComponent
} from '../../../graphics/components/dialog-product-sheet/dialog-product-sheet.component';
import {MatTableDataSource} from '@angular/material/table';


@Component({
    selector: 'app-product-table',
    templateUrl: './product-table.component.html',
    styleUrls: ['./product-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ProductTableComponent implements OnInit {
    @Input() columnsToDisplay: string[] = [];
    @Input() dataSource: MatTableDataSource<ProductSummary>;
    @Input() public headerLabels = TABLE_HEADER_LABELS;
    private visualFields: any;
    expandedElement: ElementSummary | null;
    selection = new SelectionModel<ProductSummary>(true, []);
    selectedItem: any = [];
    favoritesState$: Observable<Favorite[]>;

    constructor(
        private _userInfoService: SpUserInfoService,
        private _fileReaderImage: FileReaderService,
        private store: Store,
        public dialog: MatDialog,
        private _fileReaderService: FileReaderService,
        private productsFacade: ProductsFacade,
    ) {
        this.favoritesState$ = this.store.select(selectFavoritesList);
    }

    ngOnInit(): void {
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    getSelectedValue(item) {
        this.selectedItem.push(item);
    }

    toggleFavorite(elementSummery: ElementSummary, $event: Event) {
        $event.stopPropagation();
        elementSummery.isFavoriteElement = !elementSummery.isFavoriteElement;
        this.store.dispatch(
            FavoriteActions.toggleFavorite({
                favorites: {
                    extID: elementSummery.element.ExtID,
                    urlImage: elementSummery.urlImage,
                    label: elementSummery.label,
                    element: elementSummery.element,
                    modificationDate: elementSummery.modificationDate,
                    selected: elementSummery.isFavoriteElement,
                    urlWithoutToken: elementSummery.urlWithoutToken
                },
            })
        );
    }

    showMoreInfos(element, $event: Event) {
        $event.stopPropagation();
        this.dialog.open(DialogProductSheetComponent, {
            height: '95%',
            width: '80%',
            data: element
        });
    }
}
