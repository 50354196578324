<div class='row'>
    <div>
        <div>
            <h3>Médias</h3>
        </div>
        <mat-divider class='my-3'></mat-divider>
        <form [formGroup]='formFilter'>
            <div class='mat-form-field-text'>
                <mat-form-field appearance='outline' style="min-width: 500px;">
                    <mat-label>Rechercher</mat-label>
                    <input type='text' matInput (keyup.enter)='searchByKeyword($event)'/>
                    <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search">
                </mat-form-field>
            </div>
        </form>
        <div class='mat-elevation-z2' style="overflow-y: auto;">
            <app-loader-spinner *ngIf='isLoadingResults'></app-loader-spinner>
            <app-media-table
                    [dataSource]='mediasSummary$ | async'
                    [columnsToDisplay]='columnsToDisplay'
                    [headerLabels]='headerLabels'>
                <mat-paginator [length]='totalRows' showFirstLastButtons
                               [pageSize]='pageSize'
                               [pageSizeOptions]='[5, 10, 25, 50, 100, 200, 500]'
                               (page)='handlePageEvent($event)'
                >
                </mat-paginator>
            </app-media-table>

        </div>
    </div>
</div>
