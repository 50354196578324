import {Component, OnDestroy, OnInit} from '@angular/core';
import {NPElementType, NpUser} from "@nextpage/np-sdk-data";
import {environment} from "@env";
import {ItemBuilder, ParamsFilterBuilder, SelectedDicoBuilder} from "@data/builders";
import {TABLE_HEADER_LABELS} from "@data/constants";
import {EMPTY, Observable, Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MediaSummary, ParamsFilter, ProductSummary} from "@data/models";
import {Cards, Item, SelectedDico} from "@data/types";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MediaFacade} from "@data/facades";
import {ExportExcelService} from "../../data/services/export-excel.service";
import {SpUserInfoService} from "@data/services";
import {MatDialog} from "@angular/material/dialog";
import {LegacyPageEvent as PageEvent} from "@angular/material/legacy-paginator";
import * as _ from "lodash";
import {catchError, map, tap} from "rxjs/operators";

@Component({
    selector: 'app-medias-page',
    templateUrl: './medias-page.component.html',
    styleUrls: ['./medias-page.component.scss']
})
export class MediasPageComponent implements OnInit, OnDestroy {
    private readonly elementType: NPElementType = environment.instance.elementType;
    private paramFilterBuilder: ParamsFilterBuilder;
    public currentUser: NpUser;
    columnsToDisplay: string[];
    headerLabels = TABLE_HEADER_LABELS;
    mediasSummary$: Observable<MatTableDataSource<MediaSummary>>;
    totalRows: number;
    pageSize: number;
    isLoadingResults = false;
    filtersCriteriaSub: Subscription;
    formFilter: UntypedFormGroup;

    public isSaving = false;

    constructor(private _snackBar: MatSnackBar,
                private fb: FormBuilder,
                private _exportExcelService: ExportExcelService,
                private _userInfoService: SpUserInfoService,
                private _mediaFacade: MediaFacade,
                public dialog: MatDialog) {
        this.columnsToDisplay = ['overview', 'label', 'modificationDate', 'actions'];
        this.pageSize = 10;
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(this.pageSize).withElementTypes([this.elementType]);
        this.formFilter = this.fb.group({});
    }


    ngOnInit(): void {
        this.isLoadingResults = true;
        const paramsFilter = this.paramFilterBuilder.build();
        this.mediasSummary$ = this._loadMedias();
        this._userInfoService.getCurrentUser().subscribe((response) => {
            this.currentUser = response;
        });
    }

    ngOnDestroy(): void {
        if (this.filtersCriteriaSub) {
            this.filtersCriteriaSub.unsubscribe();
        }
    }


    searchByKeyword($event): void {
        this.isLoadingResults = true;
        const paramsFilter: ParamsFilter = this.paramFilterBuilder.withKeyword($event.target.value ?? '').build();
        this.mediasSummary$ = this._loadMedias();
    }

    handlePageEvent($event: PageEvent): void {
        const paramsFilter = this.paramFilterBuilder
            .withPage($event.pageIndex + 1)
            .withNumberOfElementByPage($event.pageSize)
            .build();
        this.isLoadingResults = true;
        this.mediasSummary$ = this._loadMedias();
    }

    _loadMedias() {
        return this._mediaFacade.getNMediasByCustomField().pipe(
            map(cards => this.cardsToMediasSummaries(cards)),
            map(mediasSummaries => {
                return new MatTableDataSource<MediaSummary>(mediasSummaries);
            }),
            tap(() => (this.isLoadingResults = false)),
            catchError(err => {
                this.isLoadingResults = false;
                this._snackBar.open('Une erreur c\'est produite veuillez recharger votre page !');
                return EMPTY;
            })
        );
    }

    cardsToMediasSummaries(cards: Cards[]): MediaSummary[] {
        return cards.map(card => {
            return {
                label: card.label,
                element: card.element,
                downloadUrl: card.downloadUrl,
                modificationDate: card.modificationDate,
                urlImage: card.urlImage,
                action: card.actions
            }
        })
    }


    private _buildItems(): Item[] {
        const items: Item[] = [];
        _.values(this.formFilter.value)
            .filter(value => 'length' in value)
            .reduce((acc, value) => [...acc, ...value])
            .map(fieldFilters => {
                const selectedDico: SelectedDico = new SelectedDicoBuilder().withID(fieldFilters.id).build();
                items.push(new ItemBuilder().withOperatorValue('=').withValue(fieldFilters.value).withSelectedDico(selectedDico).build());
            });
        return items;
    }
}
