import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {SpAuthenticationService} from '../../data/services/sp-authentication.service';
import {
  DialogResetPasswordComponent
} from '../../graphics/components/dialog-reset-password/dialog-reset-password.component';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {selectErrorMessageAuthentication, selectLoadingAuthentication} from '@store/selector';
import {Login} from '@store/action';
import {RouteName} from '../../data/constants/route.constants';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  hidePassword = true;
  returnUrl: string;
  loading$: Observable<boolean>;
  errorMessage$: Observable<string | null>;
  loginForm: FormGroup;

  constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _authenticationService: SpAuthenticationService,
    public _dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store
  ) {
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || `${RouteName.CONSULTATION}/${RouteName.HOME}`;
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.loading$ = this.store.select(selectLoadingAuthentication);
    this.errorMessage$ = this.store.select(selectErrorMessageAuthentication);
  }

  onSubmit(): void {
    if (!this.loginForm.invalid) {
      this.store.dispatch(
        Login({
          user: this.loginForm.value,
        })
      );
    }
  }

  public openResetPasswordDialog() {
    const dialogRef = this._dialog.open(DialogResetPasswordComponent, {
      width: '500px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
