import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NpUser} from '../model/np-user';

@Injectable({
  providedIn: 'root'
})
export class NpUserService {

  private readonly _urlGetCurrentUser = '/api/sdk/user/getCurrentUser';

  constructor(private _http: HttpClient) {
  }

  public getCurrentUser(): Observable<NpUser> {
    return this._http.get<Object>(this._urlGetCurrentUser)
      .pipe(
        map((data) => {
          if (data.hasOwnProperty('Results')) {
            if (data['Results'] != null) {
              return <NpUser[]>data['Results'].Users;
            }
          }
        }),
        map(users => users && users[0] ? users[0] : null),
      );
  }
}
