import {Component, OnInit} from '@angular/core';
import {selectFavoritesList} from '@store/selector';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Favorite} from '@data/models';
import {RouteResolverService} from '../../../data/services/routes/route-resolver.service';
import {RouteName} from '../../../data/constants/route.constants';

@Component({
  selector: 'app-favorite-selector',
  templateUrl: './favorite-selector.component.html',
  styleUrls: ['./favorite-selector.component.scss']
})
export class FavoriteSelectorComponent implements OnInit {
  favoritesState: Observable<Favorite[]>;

  constructor(private _routeResolverService: RouteResolverService, private store: Store) {
    this.favoritesState = this.store.select(selectFavoritesList);
  }

  ngOnInit(): void {
  }
  goToFavorite() {
    return this._routeResolverService.navigateToCurrentEntityRoute(RouteName.FAVORITE);
  }
}
