import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {EntityParameter} from '../../models/np-entity.models';
import {EntityFacade} from '../../facades/entity-facade';
import {Observable} from 'rxjs';
import {NpInstanceService} from '../np-instance.service';

export const EntityResolver: ResolveFn<EntityParameter> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    entityFacade: EntityFacade = inject(EntityFacade),
    npInstanceService: NpInstanceService = inject(NpInstanceService)
): Observable<EntityParameter> => {
    return entityFacade.getEntityParameters(npInstanceService.currentEntity.name);
};
