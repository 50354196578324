import {Component, OnInit} from '@angular/core';
import {FavoriteActions} from '@store/action';
import {Store} from '@ngrx/store';
import {ProductsFacade} from '@data/facades';
import {BaseComponent} from '../../graphics/components/base/base.component';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent extends BaseComponent implements OnInit {
    constructor(
        private store: Store,
        private _productsFacade: ProductsFacade,
    ) {
        super();
    }
  ngOnInit(): void {
    this.store.dispatch(FavoriteActions.loadFavorite());
    this._productsFacade.init();
  }
}
