import {createReducer, on} from '@ngrx/store';
import {Favorite} from '@data/models';
import {FavoriteActions} from '@store/action';

export const favoriteFeatureKey = 'favorite';

export interface FavoriteState {
    favorites: Favorite[];
}

export const initialStateFavorite: FavoriteState = {
    favorites: [],
};

export const FavoriteReducer = createReducer(
    initialStateFavorite,
    on
    (FavoriteActions.removeFromFavorite, (state, {favorites}) => ({
        ...state,
        favorites: state.favorites.filter(item => item.label !== favorites.label),
    })),
    on
    (FavoriteActions.toggleFavorite, (state, {favorites}) => ({
        ...state,
        favorites: favorites.selected
            ? [...state.favorites, favorites]
            : state.favorites.filter(item => item.label !== favorites.label),
    })),
    on
    (FavoriteActions.loadFavoriteSuccess, (state: FavoriteState, {favorites}) => ({
        ...state,
        favorites: favorites,
    }))
);
