import {Injectable} from '@angular/core';
import {NpImportExportProfilService} from './np-import-export-profil.service';
import {mergeMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ProfilDto} from '../model/import-export-profile.model';

@Injectable({
    providedIn: 'root'
})
export class NpImportServiceService {

    private _formData: FormData;
    constructor(private importService: NpImportExportProfilService,
                private _http: HttpClient) {
    }

    public importData(file: File, profileExtId: string) {
        return this.importService.getByExtId(profileExtId)
            .pipe(
                mergeMap(profile => {
                    this._buildParameters(file, profile);
                    return this._http.post('/api/sdk/Import/Product/GenerateImport', this._formData);
                })
            );
    }

    private _buildParameters(file: File, profile: ProfilDto) {
        this._formData = new FormData();
        this._formData.append('ProfileExtID', profile.ExtID);
        this._formData.append('ProfileID', profile.ID.toString());
        this._formData.append('TransfoIniConfig', ''); // Cette propriété n'est pas utilisée pour le moment
        this._formData.append('File', file, file.name); // TODO: Ajouter le fichier
        this._formData.append('UserId', '1'); // TODO: Ajouter l'Id de l'utilisateur en cours
        this._formData.append('ContextId', '1'); // TODO: Ajouter le contexte en cours
    }
}
