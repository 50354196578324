export const EXPORT_OBJECT = {
    Config: {
        ExportType: 'Elements',
        OutFileName: '',
        OneFileByLangue: false,
        Produits: [],
        DicoCaracteristiques: [],
        CaracLie: [],
        User: {
            ID: 0,
            Email: ''
        },
        Langues: [1],
        Format: 'TXT',
        ContexteID: 1,
        LangID: 1,
        JobImportExportConfigExtID: '',
        AuthorizeDownload: {
            Enable: true,
            keepDays: 1
        }
    },
    Name: 'Export Product',
    SchedulerStatus: 1,
    IsForSSIS: true
};
