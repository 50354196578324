import {Component, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectFavoritesList} from '@store/selector';
import {ElementSummary, Favorite} from '@data/models';
import {SpUserInfoService} from '@data/services';
import {FileReaderService, NpUser} from '@nextpage/np-sdk-graphics';
import {FavoriteActions} from '@store/action';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TABLE_HEADER_LABELS} from '@data/constants';
import {map} from 'rxjs/operators';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {DialogValidationExport} from "../products-page/products-page.component";
import {ExportExcelService} from "../../data/services/export-excel.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ExportExcelSummary} from "../../data/models/export-models";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ProductsFacade} from "@data/facades";
import {
    DialogProductSheetComponent
} from "../../graphics/components/dialog-product-sheet/dialog-product-sheet.component";


@Component({
    selector: 'app-favorite-page',
    templateUrl: './favorite-page.component.html',
    styleUrls: ['./favorite-page.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FavoritePageComponent implements OnInit {
    favoritesState: Observable<Favorite[]>;
    dataFavoriteToExport: Favorite[]
    columnsToDisplay: string[];
    headerLabels = TABLE_HEADER_LABELS;
    dataSource: MatTableDataSource<Favorite>;
    expandedElement: ElementSummary | null;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public currentUser: NpUser;

    private _fileName = '';
    public isSaving = false;
    public publishError: boolean;
    public errorName: string;

    constructor(private _userInfoService: SpUserInfoService,
                private _fileReaderImage: FileReaderService,
                private store: Store,
                private _exportExcelService: ExportExcelService,
                public dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _productsFacade: ProductsFacade,
    ) {
        this.favoritesState = this.store.select(selectFavoritesList);
        this.columnsToDisplay = ['overview', 'label', 'actions'];
    }

    ngOnInit(): void {
        this.favoritesState
            .pipe(
                map(favorites => {
                    return favorites.map(favorite => {
                        return {
                            ...favorite,
                            urlImage: this._fileReaderImage.toFilePath(favorite.urlWithoutToken)
                        };
                    });
                })
            )
            .subscribe(data => {
                this.dataFavoriteToExport = data;
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
            });
        this._userInfoService.getCurrentUser().subscribe((response) => {
            this.currentUser = response;
        });
    }

    removeFromFavoriteList(item) {
        this.store.dispatch(FavoriteActions.removeFromFavorite({
            favorites: {
                extID: item.extID,
                urlImage: item.urlImage,
                label: item.label,
                element: item.element,
                modificationDate: item.modificationDate,
                selected: false,
                urlWithoutToken: ''
            }
        }));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showMoreInfos(card) {
        this.dialog.open(DialogProductSheetComponent, {
            height: "95%",
            width: "65%",
            data: card
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogValidationExport, {
            width: '350px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.exportAll(this.dataFavoriteToExport)
            }
        });
    }

   private exportAll(data) {
        if (data) {
            const productsID = data.map(_currentData => _currentData.element.ID);
            this._executeExportExcel(productsID);
        }
    }

    private _startSaving() {
        this.isSaving = true;
    }

    private _stopSaving() {
        this.isSaving = false;
    }

    private _executeExportExcel(productsID) {
        this._startSaving();
        const exportExcelSummary: ExportExcelSummary = {
            fileName: this._fileName.replace(/ /g, '_'),
            userEmail: this.currentUser.Email,
            userID: this.currentUser.ID,
            productsId: productsID,
            profileExportName: this._productsFacade.getProfileExport()
        };
        this._exportExcelService.sendObject(exportExcelSummary)
            .subscribe(() => {
                this._stopSaving();
                this._snackBar.open("Un mail contenant le lien de téléchargement du fichier vous a été envoyé !");
            }, error => {
                this._stopSaving();
                this.publishError = true;
                this.errorName = error;
            });
    }
}

@Component({
    selector: 'dialog-validation-export-favorite',
    templateUrl: 'dialog-validation-export-favorite.html',
})
export class DialogValidationExportFavorite {
    constructor(public dialogRef: MatDialogRef<DialogValidationExportFavorite>) {
    }
}
