import {Injectable} from '@angular/core';
import {ProductCardActionBuilder} from '../models/product-card-action-builder';
import {ProductCardAction} from '../models/np-card';

@Injectable({
    providedIn: 'root'
})
export class CardsFacade {
    public productCardBtnActions: ProductCardAction;

    constructor() {
        this._initProductCardActions();
    }

    private _initProductCardActions() {
        this.productCardBtnActions = new ProductCardActionBuilder()
            .withAddToFavorite((card) => {
                return;
            })
            .withAddToFavorite((card) => {

            })
            .withDelete((card) => {

            })
            .withShowMore((card) => {

            })
            .build();
    }
}
