<div>
    <h3>Imports</h3>
</div>
<mat-divider class="my-3"></mat-divider>
<mat-vertical-stepper class="mat-elevation-z2">
    <mat-step>
        <ng-template matStepLabel>Import de données</ng-template>
        <!--    <div class="stepper-content">-->
        <!--      <h5 class="stepper-title">-->
        <!--        1. Téléchargez le modèle de matrice d'import-->
        <!--        <mat-icon-->
        <!--          [ngStyle]="{-->
        <!--            color: fileDownload ? 'green' : 'red'-->
        <!--          }"-->
        <!--          >{{ fileDownload ? "check" : "close" }}</mat-icon-->
        <!--        >-->
        <!--      </h5>-->
        <!--      <button mat-raised-button>Télécharger le modèle de fichier</button>-->
        <!--    </div>-->
        <div class="stepper-content">
            <h5 class="stepper-title">
                1. Importez vos données
                <mat-icon
                        [ngStyle]="{
            color: selectedFile ? 'green' : 'red'
          }"
                >{{ selectedFile ? "check" : "close" }}</mat-icon
                >
            </h5>
            <div
                    class="dropzone fullscreen"
                    fileDrop
                    (filesDropped)="handleDrop($event)"
                    (filesHovered)="handleHover($event)"
                    [class.hovered]="dropZoneHovered"
            >
                <div>
                    <div class="dropzone-info">
            <span class="selected-file">{{
                selectedFile ? fileName : "Pas de fichier selectionné."
                }}</span>
                    </div>
                    <input
                        #inputFile
                        style="display: none"
                        type="file"
                        (change)="onFileChange($event)"
                        accept="*"
                    />
                    <div class="input-file-block">
                        <p>Faites glisser un fichier</p>
                        <p>ou</p>
                        <button mat-raised-button (click)="inputFile.click()">
                            Importer le fichier
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="fileError">
                    <div class="file-error-block">
                        <span class="glyphicon glyphicon-ban-circle"></span>
                        <span>Incorrect file extension. Please select an image file.</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="steps-button-next">
            <button
                    mat-raised-button
                    color="primary"
                    matStepperNext
                    [disabled]="!selectedFile"
            >
                Suivant
            </button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Vérification des données</ng-template>
        <div class="stepper-content" style="overflow: auto;">
            <h5 class="stepper-title">2. Vérifiez vos données</h5>
            <div class="stepper-table">
                <table
                        *ngIf="dataSummary && dataSummary.data && dataSummary.data.length > 0"
                        mat-table
                        [dataSource]="dataSummary.data"
                >
                    <ng-container
                            *ngFor="let header of dataSummary.headers"
                            [matColumnDef]="header"
                    >
                        <th mat-header-cell *matHeaderCellDef>{{ header }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row[header] }}
                        </td>
                    </ng-container>
                    <tr
                            mat-header-row
                            *matHeaderRowDef="dataSummary.headers; sticky: true"
                    ></tr>
                    <tr mat-row *matRowDef="let row; columns: dataSummary.headers"></tr>
                </table>
            </div>
        </div>
        <div class="steps-buttons">
            <button mat-raised-button matStepperPrevious>Retour</button>
            <button mat-raised-button color="primary"
                    [disabled]='isLoading'
                    (click)="executeDataImport()">
                <span
                        *ngIf="isLoading"
                        class='spinner-border spinner-border-sm mr-1'
                ></span>Valider l'import
            </button>
        </div>
    </mat-step>
</mat-vertical-stepper>
