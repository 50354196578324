<div>
  <h4 mat-dialog-title>Mot de passe oublié ?</h4>
  <mat-divider class="mb-4"></mat-divider>
  <form (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field class="inputEmail" appearance="outline">
        <mat-label>Email</mat-label>
        <mat-icon matSuffix>email</mat-icon>
        <input
          [formControl]="emailFormControl"
          pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
          matInput
          type="email"
          name="email"
          required
        />
        <mat-error>Ce champ est requis !</mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-button-actions">
      <div>
        <button mat-button (click)="close(); $event.preventDefault()">
          Fermer
        </button>
      </div>
      <div>
        <button [disabled]="loading" mat-raised-button color="primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Recevoir un nouveau mot de passe
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
<!-- <div id="reset-block" class="page-form-block">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 form-group">
        <label for="email">E-mail</label>
        <input
          type="email"
          formControlName="email"
          class="form-control"
          id="email"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email address required</div>
          <div *ngIf="f.email.errors.email">Invalid E-mail address</div>
        </div>
      </div>
    </div>
  </form>
</div> -->
