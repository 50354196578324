<div id="element-filter-header">
<!--    <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText class="np-input-custom" placeholder="Rechercher"/>
    </span>-->
    <mat-accordion class="">
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title class="gap-container-1">
                    <i class="pi pi-filter" style="color: slateblue"></i>
                    <span>{{filterTitle}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-content></ng-content>
        </mat-expansion-panel>
    </mat-accordion>
</div>


