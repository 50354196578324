import {Component, Input, OnInit} from '@angular/core';
import {NpCaracConfigService} from './data/services/np-carac-config.service';
import {NpInstanceService} from "./data/services/np-instance.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @Input() instanceId: string;
    public instanceBg$ = this._instanceService.getBgImageInstance();

    constructor(private _caracConfigService: NpCaracConfigService,
                private _instanceService: NpInstanceService
    ) {
    }

    ngOnInit() {
        this._caracConfigService.getAll()
            .subscribe((response) => {
                console.log('****************** : ', response)
            });
    }
}
