import {NPElement} from './np-element';
import {Properties} from './search-model';

export interface NPSearchResult {
  resultsCount: number;
  elements: NPElement[];
  visualDicoCaracExtID?: string;
}

// Recherche avancée minifier
export interface Label {
  DicoCaracID: number;
  Value: string;
  Values?: any;
}

export interface Droits {
  View: boolean;
  Edit: boolean;
  Create: boolean;
  Delete: boolean;
  Export: boolean;
  Translate: boolean;
  AdvancedRight: boolean;
}

export interface Model {
  RankLevel: number;
  Properties: Properties;
  Access: number;
  Droits: Droits;
  ParentsID: number[];
  ParentsExtID: string[];
  WFName?: any;
  WFColor?: any;
  WFID: number;
  HasChildren: boolean;
  CountChild: number;
  BranchRights?: any;
  RootElementExtID: string;
  ID: number;
  ParentsName: string;
  ExtID: string;
  Type: string;
  label: string;
  Name: string;
  ElementType: number;
  ParentID: number;
  Inf: number;
  Sup: number;
  ElementLastModification: Date;
}

export interface Header {
  ID: number;
  Name: string;
  Unite?: any;
  AdvancedSearchID: number;
  FieldType: number;
  DicoCaracID?: any;
  DicoCaracRenderType: string;
  DicoCaracTypeCode?: any;
  DicoCaracIDs?: any;
  Order: number;
  SortAllow: boolean;
  SortEnable: boolean;
  SortDesc: boolean;
}

export interface NPSearchMinResult {
  Model: Model[];
  Count: number;
  Headers: Header[];
  Criterias: any[];
  Trace: any[];
}

// **************************
