<div>
  <button
    mat-flat-button
    [matMenuTriggerFor]="menu"
    aria-label="Language"

  >
    <span>
      <img
        src="assets/flags/4x3/fr.svg"
        height="25"
        width="30"
        alt="Drapeau Français"
      />
    </span>
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button class="center-item" mat-menu-item>
      <span>
        <img
          src="assets/flags/4x3/fr.svg"
          height="25"
          width="30"
          alt="Drapeau Français"
        />
      </span>
    </button>
<!--    <button class="center-item" mat-menu-item>-->
<!--      <span>-->
<!--        <img-->
<!--          src="assets/flags/4x3/gb.svg"-->
<!--          height="25"-->
<!--          width="30"-->
<!--          alt="Drapeau Anglais"-->
<!--        />-->
<!--      </span>-->
<!--    </button>-->
  </mat-menu>
</div>
