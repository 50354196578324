import {Component, OnInit} from '@angular/core';
import {ParamsFilter, ProductSummary} from '@data/models';
import {Observable} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {SpDicoCarac} from '@data/constants';
import {map, switchMap} from 'rxjs/operators';
import {ProductsFacade} from '@data/facades';
import {ParamsFilterBuilder} from '@data/builders';
import {NPElementType, WorkflowRepository} from '@nextpage/np-sdk-data';
import {environment} from '@env';

@Component({
    selector: 'app-np-elements-table-view',
    templateUrl: './np-elements-table-view.component.html',
    styleUrls: ['./np-elements-table-view.component.scss']
})
export class NpElementsTableViewComponent implements OnInit {
    public productsSummary$: Observable<MatTableDataSource<ProductSummary>>;
    private paramFilterBuilder: ParamsFilterBuilder;
    private readonly elementType: NPElementType = environment.instance.elementType;
    private pageSize = 10;
    public elements: ProductSummary[] = [];

    constructor(private productsFacade: ProductsFacade, private _workflowRepository: WorkflowRepository) {
    }

    ngOnInit(): void {
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(this.pageSize).withElementTypes([this.elementType]);
        const paramsFilter = this.paramFilterBuilder.build();
        // this.productsSummary$ = this._loadProduct(paramsFilter);
        this._loadProduct(paramsFilter)
            .pipe(
                switchMap((elementSummaries: ProductSummary[]) => {
                    // TODO: A implémenter lors de la récupération de données
                    return this._workflowRepository.getStatusAccess()
                        .pipe(
                            map(statusMap => {
                                return elementSummaries.map(elementSummary => {
                                    elementSummary.status = statusMap.get(elementSummary.element.getValueStatus().StatusExtID);
                                    return elementSummary;
                                });
                            })
                        );
                })
            )
            .subscribe(data => {
                this.elements = data;
            });

    }

    private _loadProduct(paramsFilter: ParamsFilter): Observable<ProductSummary[]> {
        return this.productsFacade.listProductWithPagination(paramsFilter).pipe(
            map(productsSummaryWithTotalRow => {
                return productsSummaryWithTotalRow.productSummaryList;
            })
        );
    }


}
