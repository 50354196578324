import {ProductCardAction} from './np-card';

export class ProductCardActionBuilder {
    private _addToFavorite!: Function;
    private _delete!: Function;
    private _showMore!: Function;

    withAddToFavorite(value: Function): ProductCardActionBuilder {
        this._addToFavorite = value;
        return this;
    }

    withDelete(value: Function): ProductCardActionBuilder {
        this._delete = value;
        return this;
    }

    withShowMore(value: Function): ProductCardActionBuilder {
        this._showMore = value;
        return this;
    }

    build(): ProductCardAction {
        return {
            addToFavorite: this._addToFavorite,
            delete: this._delete,
            showMore: this._showMore,
        };
    }
}
