import {Component, Input} from '@angular/core';
import {ProductSummary} from '@data/models';

@Component({
    selector: 'app-elements-table',
    templateUrl: './elements-table.component.html',
    styleUrls: ['./elements-table.component.scss']
})
export class ElementsTableComponent {

    @Input() elements: ProductSummary[] = [];

    public layout = 'list';
}
