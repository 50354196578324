import {NPCaracValeur} from './np-carac-valeur';
import {NPElementType} from './np-element';
import {AdvSearchArgs} from './adv-search-args';

export class PropagationConfig {
    ParentID: number;
    Value: NPCaracValeur;
    ChildrenElementType: NPElementType;
    AdvSearch: AdvSearchArgs;
    Count: number;
}
