import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {FavoriteActions} from '@store/action';
import {Favorite} from '@data/models';
import {NPCaracValeur, NPElement} from "@nextpage/np-sdk-data";
import {AsbCard} from "../../data/models/np-card";

@Injectable()
export class FavoriteEffect {
    constructor(private actions$: Actions) {
    }
    getFavoritesFromLocalStorage(): Favorite[] {
        const storedFavorites = localStorage.getItem('Favorite List');
        if (storedFavorites) {
            let dataFromLocalStorage: AsbCard[] = JSON.parse(storedFavorites);
           return dataFromLocalStorage.map(item => {
                item.element = new NPElement(item.element);
                return item
            })

            // const updatedDataWithMapValues = [];
            // for (const item of dataFromLocalStorage) {
            //     const valuesMap = new Map<string, NPCaracValeur>();
            //
            //     // Convertit les paires clé-valeur en tableau
            //     const entries = Object.entries(item.element.Values);
            //     // Reconstruit la Map à partir du tableau
            //     entries.forEach(([mapKey, mapValue]) => {
            //         valuesMap.set(mapKey, mapValue as NPCaracValeur);
            //     });
            //
            //     // Met à jour la propriété Values avec la Map
            //     const updatedElement = {...item.element, Values: valuesMap};
            //     // Met à jour l'élément dans le tableau principal
            //     const updatedItem = {...item, element: updatedElement};
            //     updatedDataWithMapValues.push(updatedItem);
            // }
            //
            // return updatedDataWithMapValues;
        } else {
            return [];
        }
    }

    loadFavorite$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(FavoriteActions.loadFavorite),
                map(() => {
                    const favorites = this.getFavoritesFromLocalStorage();
                    return FavoriteActions.loadFavoriteSuccess({favorites: favorites});
                })
            );
        },
    );

    LoadFavoriteSuccess$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(FavoriteActions.loadFavoriteSuccess),
            );
        },
        {dispatch: false}
    );

    toggleFavorite$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavoriteActions.toggleFavorite),
                tap(action => {
                    const favorite = action.favorites;
                    const storedFavorites = this.getFavoritesFromLocalStorage();
                    const updatedFavorites = storedFavorites.filter(item => item.extID !== favorite.extID);
                    if (favorite.selected) {
                        updatedFavorites.push(favorite);
                    }
                    const updatedFavoritesWithSerializedValues = updatedFavorites.map(favorites => {
                        const valuesArray = Array.from(favorites.element.Values.entries());
                        const serializedValues = valuesArray.reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {});
                        return {...favorites, element: {...favorites.element, Values: serializedValues}};
                    });

                    localStorage.setItem(
                        'Favorite List',
                        JSON.stringify(updatedFavoritesWithSerializedValues)
                    );
                })
            );
        },
        {dispatch: false}
    );

    removeFromFavorite$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(FavoriteActions.removeFromFavorite),
                tap(({favorites}) => {
                    const storedFavorites = this.getFavoritesFromLocalStorage();
                    const updatedFavorites = storedFavorites.filter(item => item.extID !== favorites.extID);
                    localStorage.setItem('Favorite List', JSON.stringify(updatedFavorites));
                })
            );
        },
        {dispatch: false}
    );
}
