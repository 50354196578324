import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';

import { ProductSummary } from '@data/models';
import { ProductsFacade } from '@data/facades';
import { Field } from '@data/types';

@Component({
  selector: 'app-product-table-expanded',
  templateUrl: './product-table-expanded.component.html',
  styleUrls: ['./product-table-expanded.component.scss'],
  animations: [
    trigger('isLoading', [
      state(
        'loadingData',
        style({
          opacity: 0,
        })
      ),
      state(
        'loaded',
        style({
          opacity: 1,
        })
      ),
      transition('loadingData => loaded', [animate('0.2s')]),
    ]),
  ],
})
export class ProductTableExpandedComponent implements OnInit, OnDestroy {
  @Input()
  productSummary: ProductSummary;
  public fields: Field[];
  public fields$: Subscription;
  public loading: boolean = false;

  constructor(private productFacade: ProductsFacade) {}

  ngOnInit(): void {
    this.loading = true;
    // this.fields$ = this.productFacade.getInfoForProduct(this.productSummary).subscribe(fields => {
    //   this.loading = false;
    //   this.fields = fields;
    // });
  }

  ngOnDestroy() {
    this.fields$.unsubscribe();
  }
}
