import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-media-sheet',
  templateUrl: './dialog-media-sheet.component.html',
  styleUrls: ['./dialog-media-sheet.component.scss']
})
export class DialogMediaSheetComponent implements OnInit{
  public loading: boolean = false;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
  ) {
  }

  ngOnInit(): void {
    // this.loading = true;
  }

  print() {
    window.print();
  }
}
