import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import {map, mergeMap, switchMap, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {
    CustomField,
    DicoCarac,
    DicocaracRepository,
    ElementRepository,
    FileReaderService,
    NPCaracListe,
    NPCaracValeur,
    NPDicoCarac,
    NPElement,
    NPElementType,
    NPSearchResult,
    SearchRepository,
    TypeCode,
} from '@nextpage/np-sdk-data';

import {ElementLabels, SearchDisplayFieldType, SpDicoCarac} from '@data/constants';
import {Characteristic, CharTemplateDto, ParamsFilter, ProductSummary} from '@data/models';
import {
    AdvancedSearchDTOBuilder,
    AdvSearchArgsBuilder,
    FamilyBuilder,
    FiltersBuilder,
    ParamsFilterBuilder,
    SortFieldBuilder,
} from '@data/builders';
import {AdvSearchArgs, Cards, ChoiceCriteria, Facet, Field, ProductSummaryWithTotalRow} from '@data/types';
import {ProductService, SpUserInfoService, TableHandlerService} from '@data/services';
import {environment} from '@env';

@Injectable({
    providedIn: 'root',
})
export class ProductsFacade {
    private extIdVisuel: string;

    constructor(
        private _userInfoService: SpUserInfoService,
        private _elementRepository: ElementRepository,
        private _searchRepository: SearchRepository,
        private _tableHandlerService: TableHandlerService,
        private _fileReaderService: FileReaderService,
        private _productService: ProductService,
        private _dicoCaracRepository: DicocaracRepository
    ) {
    }

    public init() {
        this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_VISUEL)
            .pipe(
                switchMap(userFields => {
                    return userFields && userFields.Values?.length > 0 ? of(userFields.Values[0].Value) : throwError(`${SpDicoCarac.CP_EXT_ID_VISUEL} no found.`);
                })
            )
            .subscribe(visualCaracExtId => {
                this.extIdVisuel = visualCaracExtId;
            }, error => console.error(error));
    }

    getNProductsByCustomField(paramsFilter: ParamsFilter): Observable<Cards[]> {
        let typeCode: TypeCode;
        return this.paginatedProductsList(paramsFilter).pipe(
            mergeMap((npSearchResult: NPSearchResult) => {
                return this._dicoCaracRepository.getDicoCarac(npSearchResult.visualDicoCaracExtID).pipe(
                    map(dicoCarac => {
                        typeCode = dicoCarac.TypeCode;
                        return npSearchResult;
                    })
                );
            }),
            map((npSearchResult: NPSearchResult) => npSearchResult.elements),
            map(npElements => npElements.map(this.npElementToCards)),
            map(cards => cards.map(card => this.assignImageUrlForCards(card, typeCode)))
        );
    }

    listProductWithPagination(paramsFilter: ParamsFilter): Observable<ProductSummaryWithTotalRow> {
        let totalsRows: number;
        let typeCode: TypeCode;
        return this.paginatedProductsList(paramsFilter).pipe(
            mergeMap((npSearchResult: NPSearchResult) => {
                return this._dicoCaracRepository.getDicoCarac(npSearchResult.visualDicoCaracExtID).pipe(
                    map(dicoCarac => {
                        totalsRows = npSearchResult.resultsCount;
                        typeCode = dicoCarac.TypeCode;
                        return npSearchResult;
                    })
                )
            }),
            map((npSearchResult: NPSearchResult) => npSearchResult.elements),
            map(npElements => npElements.map(element => this.npElementToProductSummary(element, typeCode))),
            map(
                (productSummaryList: ProductSummary[]) =>
                    ({productSummaryList: productSummaryList, totalsRows: totalsRows} as ProductSummaryWithTotalRow)
            )
        );
    }

    getInfoForProduct(productSummary: ProductSummary) {
        return combineLatest([
            this.getCharacteristicForParent(productSummary.element),
            this.getCharacteristicForProductSummary(productSummary.element),
            this._productService.getCharacteristicConfigListByUser(),
            this._dicoCaracRepository.getAll()
        ]).pipe(
            map(([mapCaracForParent, mapCaracProduct, characteristicList, dicoCaracs]) =>
                this.getFields(mapCaracForParent, mapCaracProduct, characteristicList, dicoCaracs, productSummary.element)
            )
        );
    }

    listFilters(): Observable<CharTemplateDto[]> {
        return this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_PRODUIT).pipe(
            map(userField => [userField?.Values[0].Value]),
            switchMap(extIdFamily => {
                return combineLatest([this._elementRepository.getProductTemplate(extIdFamily), this._dicoCaracRepository.getAll()]);
            }),
            map(([charTemplateDtoList, dicoCarac]) => {
                return charTemplateDtoList.map((charTemplateDto: CharTemplateDto) => {
                    return {
                        ...charTemplateDto,
                        CharTemplateChoiceCriterias: this.completeChoiceCriteriaWithLabelAndExtId(
                            charTemplateDto.CharTemplateChoiceCriterias,
                            dicoCarac
                        ),
                    };
                });
            })
        );
    }

    public completeChoiceCriteriaWithFacets(
        choiceCriteriaList: ChoiceCriteria[],
        dicoCaracExtIdProduct: string,
        productTypeId: number
    ): Observable<ChoiceCriteria[]> {
        const facetsList: string[] = choiceCriteriaList.map(data => data.ext_id);
        if (facetsList.length > 0) {
            const paramsFilter = new ParamsFilterBuilder()
                .withElementTypes([NPElementType.Product, NPElementType.Reference])
                .withFacetList(facetsList)
                .withProductTypeId(productTypeId)
                .build();
            return this.getParentId().pipe(
                mergeMap(familyId => {
                    return this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_VISUEL)
                        .pipe(
                            map(userField => {
                                const fieldUser = userField && userField.Values && userField.Values.length > 0 ? userField.Values[0].Value : ''
                                return {
                                    familyId: familyId,
                                    paths: [fieldUser]
                                }
                            })
                        )
                }),
                mergeMap(response => {
                    const advSearchArgs = this.generateFiltersFind(response.familyId, paramsFilter, response.paths);
                    return this._searchRepository.searchFacets(advSearchArgs);
                }),
                map((facets: Facet[]) => {
                    return choiceCriteriaList.map(choiceCriteria => {
                        const facetsByExtId = facets.filter(facet => facet.DCExtID === choiceCriteria.ext_id);
                        return {
                            ...choiceCriteria,
                            facets: _.sortBy(facetsByExtId, 'Value'),
                        };
                    });
                })
            );
        }
    }

    private getCharacteristicForParent(element: NPElement): Observable<Map<string, NPCaracValeur>> {
        if (element.ElementType === NPElementType.Product) {
            return of(new Map());
        }
        const parentExtId = element.ParentExtID;
        return this._productService.getCharacteristicConfigListByUser().pipe(
            mergeMap(characteristicList => {
                return this._elementRepository.getElementWithProperties(
                    [parentExtId],
                    [[]],
                    characteristicList.map(characteristic => characteristic.ExtID)
                );
            }),
            map(mapElement => mapElement.get(parentExtId).Values)
        );
    }

    private getCharacteristicForProductSummary(element: NPElement): Observable<Map<string, NPCaracValeur>> {
        const productValuesMap = element.Values;
        return this._productService.getCharacteristicConfigListByUser().pipe(
            map((characteristicList: Characteristic[]) => {
                const characteristicMap = new Map<string, NPCaracValeur>();
                for (const characteristic of characteristicList) {
                    const nbCaracValeur = productValuesMap.get(characteristic.ExtID);
                    if (nbCaracValeur) {
                        characteristicMap.set(characteristic.ExtID, nbCaracValeur);
                    }
                }
                return characteristicMap;
            })
        );
    }

    public getParentId(): Observable<number> {
        return this._userInfoService.getParentExtId()
            .pipe(
                map(channelField => new CustomField(channelField)),
                switchMap((channelField: CustomField) => {
                    const channelExtId = channelField.getFirstTextValue();
                    return this._elementRepository.getElements([channelExtId], [], [DicoCarac.CHANNEL_LABEL, DicoCarac.FAMILY_LABEL])
                        .pipe(
                            mergeMap(elements => {
                                return elements.has(channelExtId) ?
                                    of(elements.get(channelExtId).ID) :
                                    throwError('Channel extId not found');
                            })
                        );
                }),
            );
    }

    private paginatedProductsList(paramsFilter: ParamsFilter): Observable<NPSearchResult> {
        let fieldUser = '';
        return this.getParentId().pipe(
            mergeMap(familyId => {
                return this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_VISUEL)
                    .pipe(
                        map(userField => {
                            fieldUser = userField && userField.Values && userField.Values.length > 0 ? userField.Values[0].Value : '';
                            return {
                                familyId: familyId,
                                paths: [fieldUser]
                            }
                        })
                    )
            }),
            mergeMap((response) => this._searchRepository.Search(this.generateFiltersFind(response.familyId, paramsFilter, response.paths))),
            map(nPSearchResult => {
                return {
                    ...nPSearchResult,
                    visualDicoCaracExtID: fieldUser,
                    elements: nPSearchResult.elements,
                };
            })
        );
    }

    public getImageUrl(element: NPElement, typeCode: TypeCode): string {
        return this._fileReaderService.toFilePath(this.getImageUrlWithoutToken(element, typeCode));
    }

    private getImageUrlWithoutToken(element: NPElement, typeCode: TypeCode): string {
        let urlImage = '';
        if (typeCode === TypeCode.LIEN) {
            const rebuildLinkImages = element?.getValueLien(this.extIdVisuel)?.RebuildLinkedElements;
            if (rebuildLinkImages && rebuildLinkImages.length > 0) {
                urlImage = rebuildLinkImages[0]?.Element?.getValueTextValue(DicoCarac.MEDIA_FILE);
            }
            return urlImage
        } else {
            urlImage = [this.extIdVisuel].reduce((url, key) => {
                const value = element?.getValueTextValue(key);
                return url || value;
            }, '');
            return urlImage;
        }
    }

    public getProfileExport() {
        let profileExport: string;
        this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_PROFILE_EXPORT).subscribe((field => profileExport = field.Values[0]?.Value));
        return profileExport;
    }

    private getPdfUrl(pdfUrl): string {
        return this._fileReaderService.toFilePathToDownload(pdfUrl) ?? '';
    }

    private npElementToCards(element: NPElement): Cards {
        const lastModifiedInfos = TableHandlerService.getLastModifiedInfos(element);
        return {
            label: element.getValueTextValue(ElementLabels[element.ElementType]),
            modificationDate: TableHandlerService.toDate(lastModifiedInfos),
            actions: '', // Permet de créer la colonne "Actions" dans le tableau pour afficher les boutons
            element: element,
            lastModificationUserName: lastModifiedInfos['ModifiedByUserName'] || ''
        };
    }

    private assignImageUrlForCards(cards: Cards, typeCode: TypeCode): Cards {
        return {
            ...cards,
            urlImage: this.getImageUrl(cards.element, typeCode),
            urlWithoutToken: this.getImageUrlWithoutToken(cards.element, typeCode)
        };
    }

    private npElementToProductSummary(element: NPElement, typeCode: TypeCode): ProductSummary {
        const lastModifiedInfos = TableHandlerService.getLastModifiedInfos(element);
        const productSummary = new ProductSummary(
            element.getValueTextValue(ElementLabels[element.ElementType]),
            TableHandlerService.toLocalDate(lastModifiedInfos),
            element,
            lastModifiedInfos['ModifiedByUserName'] || '',
            this.getImageUrl(element, typeCode),
        );
        productSummary.urlWithoutToken = this.getImageUrlWithoutToken(element, typeCode);
        return productSummary;
    }

    private generateFiltersFind(familyId: number, paramsFilter: ParamsFilter, paths: string[]): AdvSearchArgs {
        const sortField = new SortFieldBuilder().withFieldType(SearchDisplayFieldType.ModificationDate).build();
        const filters = new FiltersBuilder()
            .withKeywords(paramsFilter.keyword)
            .withProductsTypeID(paramsFilter.productTypeId)
            .withElementTypes(paramsFilter.elementTypeList)
            .withChars(paramsFilter.chars);

        // Récupération du parent (Famille ou Canal)
        const parent = new FamilyBuilder().withID(familyId).build();
        if (environment.useChannelSearch) {
            filters.withChannel(parent);
        } else {
            filters.withFamily(parent);
        }

        const advancedSearchDTO = new AdvancedSearchDTOBuilder().withSortFields([sortField]).withFilters(filters.build()).build();
        return new AdvSearchArgsBuilder()
            .withPageSize(paramsFilter.numberOfElementByPage)
            .withCurrentPage(paramsFilter.page)
            .withPaths([[SpDicoCarac.DC_P_CROSS_SELLING], paths])
            .withFacets(paramsFilter.facetsList)
            .withConfig(advancedSearchDTO)
            .build();
    }

    private getFields(
        mapCaracForParent: Map<string, NPCaracValeur>,
        mapCaracProduct: Map<string, NPCaracValeur>,
        characteristicList: Characteristic[],
        dicoCaracList: NPDicoCarac[],
        element: NPElement
    ) {
        const combineCharacteristicUser = new Map([...mapCaracForParent, ...mapCaracProduct]);
        const fields: Field[] = [];
        const linkedCaracs = characteristicList.filter(carac => carac.typeCode.indexOf('LIEN') !== -1)
        const linkedElementsProducts = linkedCaracs.reduce((acc: { urlImage: string, label: string }[], carac) => {
            const linkedElements = this.getLinkedProductsItems(element?.getValueLien(carac?.ExtID)?.RebuildLinkedElements?.map(result => result?.Element));
            acc = [...acc, ...linkedElements]
            return acc
        }, [])
        for (let characteristic of characteristicList) {
            const dicoCaracForChoiceCriteria = dicoCaracList?.find(dicoCarac => dicoCarac.ExtID === characteristic.ExtID);
            const descriptif = combineCharacteristicUser.get(characteristic.ExtID);
            const regexPdf = /\.(pdf)$/;
            if (descriptif) {
                if (descriptif.TypeValue === 0) {
                    fields.push({
                        label: characteristic.label,
                        value: descriptif.Value,
                        pdf: descriptif.Value.match(regexPdf) ? this.getPdfUrl(descriptif.Value) : '',
                        unit: dicoCaracForChoiceCriteria?.Unite,
                    });
                } else if (descriptif.TypeValue === 2) {
                    const characteristicList: NPCaracListe = descriptif as NPCaracListe;
                    fields.push({
                        label: characteristic.label,
                        value: characteristicList.Values.map(charac => charac.Label),
                        pdf: characteristicList.Values.map(charac => charac.Label.match(regexPdf) ? this.getPdfUrl(charac.Label) : ''),
                        unit: dicoCaracForChoiceCriteria?.Unite
                    });
                }
            }
        }
        return ({fields, linkedElementsProducts});
    }

    private completeChoiceCriteriaWithLabelAndExtId(choiceCriteriaList: ChoiceCriteria[], dicoCaracList: NPDicoCarac[]): ChoiceCriteria[] {
        return choiceCriteriaList.map<ChoiceCriteria>(choiceCriteria => {
            const dicoCaracForChoiceCriteria = dicoCaracList.filter(dicoCarac => dicoCarac.ID === choiceCriteria.DicoCaracID)[0];
            if (dicoCaracForChoiceCriteria) {
                return {
                    ...choiceCriteria,
                    label: dicoCaracForChoiceCriteria.Name,
                    ext_id: dicoCaracForChoiceCriteria.ExtID,
                    typeCode: dicoCaracForChoiceCriteria.TypeCode,
                    dicoCaracExtId: dicoCaracForChoiceCriteria.ExtID
                };
            }
        });
    }

    getLinkedProductsItems(element: NPElement[]): { urlImage: string, label: string }[] {
        return element.map(elem => ({
            urlImage: this.getImageUrl(elem, TypeCode.LIEN),
            label: elem.getValueTextValue(DicoCarac.PRODUCT_LABEL)
        }))
    }
}
