import { SortType } from '../types/sort-type';
import { SearchDisplayFieldType } from '../constants/search-display-field-type';

export class SortFieldBuilder {
  private _FieldType: SearchDisplayFieldType;
  private _SortDesc: boolean;

  withFieldType(value: SearchDisplayFieldType): SortFieldBuilder {
    this._FieldType = value;
    return this;
  }

  withSortDesc(value: boolean): SortFieldBuilder {
    this._SortDesc = value;
    return this;
  }

  build(): SortType {
    return {
      FieldType: this._FieldType,
      SortDesc: this._SortDesc ?? false,
    };
  }
}
