import {Directive, EventEmitter, HostListener, Output} from '@angular/core';


@Directive({
    selector: '[fileDrop]'
})
export class FileDropDirective {
    @Output() filesHovered = new EventEmitter<boolean>();
    @Output() filesDropped = new EventEmitter<FileList>();

    constructor() {
    }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        if ($event.dataTransfer.files.length === 1) {
            this.filesDropped.emit($event.dataTransfer.files[0]);
        }
        this.filesHovered.emit(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        this.filesHovered.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        this.filesHovered.emit(false);
    }
}
