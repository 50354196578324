import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ElementSummary} from '../../../data/models/sp-models';
import {DialogElementWriterComponent} from '../dialog-element-writer/dialog-element-writer.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private _dialog: MatDialogRef<DialogElementWriterComponent>;

    constructor(public _dialogRef: MatDialog) {
    }

    public openElementWriterDialog(element: ElementSummary) {
        this._dialog = this._dialogRef.open(DialogElementWriterComponent, {
            data: {
                elementSummary: element,
            },
            hasBackdrop: true,
            disableClose: true,
            height: '85%',
            width: '90%',
        });
    }
}
