<mat-card class='authentication-card mx-auto'>
  <mat-card-header class='authentication-card-header'>
    <mat-card-title class='header-image'>
      <img src='assets/logo-nextpage.png' alt='Logo Nextpage' />
    </mat-card-title>
    <mat-card-title class='header-title'>Portail</mat-card-title>
  </mat-card-header>
  <form [formGroup]='loginForm' (ngSubmit)='onSubmit()'>
    <mat-card-content class='authentication-card-content'>
      <mat-form-field appearance='outline'>
        <mat-label>Username</mat-label>
        <mat-icon matSuffix>person_outline</mat-icon>
        <input matInput type='text' formControlName='login' required />
        <mat-error>Ce champ est requis !</mat-error>
      </mat-form-field>
      <mat-form-field appearance='outline'>
        <mat-label>Password</mat-label>
        <input
          matInput
          type='password'
          formControlName='password'
          required
          [type]="hidePassword ? 'password' : 'text'"
        />
        <button
          mat-icon-button
          matSuffix
          type='button'
          (click)='hidePassword = !hidePassword; $event.preventDefault()'
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]='hidePassword'
        >
          <mat-icon>{{
            hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
        </button>
        <mat-error>Ce champ est requis !</mat-error>
      </mat-form-field>
      <mat-error *ngIf='(errorMessage$ | async)' class='errorMessage'>
        Vos indentifiants sont incorrects.
      </mat-error>
    </mat-card-content>
    <div class='buttonForgotPassword'>
      <button
        mat-button
        type='button'
        (click)='openResetPasswordDialog(); $event.preventDefault()'
      >
        Mot de passe oublié ?
      </button>
    </div>
    <mat-card-actions class='authentication-card-buttons'>
      <button type='submit'
              class='buttonConnection'
              color='primary'
              mat-raised-button
              [disabled]='loginForm.invalid || (loading$ | async)'
      >
        <span
          *ngIf='(loading$ | async)'
          class='spinner-border spinner-border-sm mr-1'
        ></span>
        Se connecter
      </button>
    </mat-card-actions>
  </form>
</mat-card>
