import {BrowserModule} from '@angular/platform-browser';
import {isDevMode, NgModule} from '@angular/core';

import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import {environment} from '@env';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomePageComponent} from './views/home-page/home-page.component';
import {DialogValidationExport, ProductsPageComponent} from './views/products-page/products-page.component';
import {ImportPageComponent} from './views/import-page/import-page.component';
import {AccountPageComponent} from './views/account-page/account-page.component';
import {AuthenticationComponent} from './views/authentication/authentication.component';
import {
    DialogResetPasswordComponent
} from './graphics/components/dialog-reset-password/dialog-reset-password.component';
import {SharedModule} from './graphics/shared/shared.module';
import {UserNameComponent} from './graphics/components/user-name/user-name.component';
import {SupplierInformationComponent} from './graphics/components/supplier-information/supplier-information.component';
import {MainPageComponent} from './views/main-page/main-page.component';
import {SidebarContentComponent} from './graphics/components/sidebar-content/sidebar-content.component';
import {LogoSupplierComponent} from './graphics/components/logo-supplier/logo-supplier.component';
import {PieChartComponent} from './graphics/components/pie-chart/pie-chart.component';
import {UserInformationComponent} from './graphics/components/user-information/user-information.component';
import {
    ProductTableExpandedDetailViewComponent
} from './graphics/components/product-table/product-table-expanded-detail-view/product-table-expanded-detail-view.component';
import {
    ProductTableCellViewComponent
} from './graphics/components/product-table/product-table-cell-view/product-table-cell-view.component';
import {
    DialogElementWriterComponent
} from './graphics/components/dialog-element-writer/dialog-element-writer.component';
import {DialogLoaderComponent} from './graphics/components/dialog-loader/dialog-loader.component';
import {SnackBarComponent} from './graphics/components/snack-bar/snack-bar.component';

import {LoaderSpinnerComponent} from './graphics/components/loader-spinner/loader-spinner.component';
import {LanguageSelectorComponent} from './graphics/components/language-selector/language-selector.component';
import {MediaCardsComponent} from './graphics/components/media-cards/media-cards.component';
import {DialogValidationExportFavorite, FavoritePageComponent} from './views/favorite-page/favorite-page.component';
import {FavoriteTableComponent} from './graphics/components/favorite-table/favorite-table.component';
import {FavoriteSelectorComponent} from './graphics/components/favorite-selector/favorite-selector.component';

import {authenticationFeatureKey, authenticationReducer, favoriteFeatureKey, FavoriteReducer} from '@store/reducers';

import {AuthenticationEffects, FavoriteEffect} from '@store/effects';
import {
    ProductTableExpandedComponent
} from './views/products-page/product-table-expanded/product-table-expanded.component';
import {ProductTableComponent} from './views/products-page/product-table/product-table.component';
import {FavoritePipe} from '@data/pipes';
import {DialogProductSheetComponent} from './graphics/components/dialog-product-sheet/dialog-product-sheet.component';
import {NgOptimizedImage} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './data/interceptors/auth.interceptor';
import {MediasPageComponent} from './views/medias-page/medias-page.component';
import {MediaTableComponent} from './views/medias-page/media-table/media-table.component';
import {CardsComponent} from './graphics/components/cards/cards.component';
import {CardComponent} from './graphics/components/cards/card/card.component';
import {NoInstanceFoundComponent} from './views/no-instance-found/no-instance-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NpElementsTableViewComponent} from './views/np-elements-table-view/np-elements-table-view.component';
import {
    NpElementsTableHeaderComponent
} from './views/np-elements-table-view/np-elements-table-header/np-elements-table-header.component';
import {
    ProductFilterViewComponent
} from './views/np-elements-table-view/product-filter-view/product-filter-view.component';
import {ElementsTableComponent} from './views/np-elements-table-view/elements-table/elements-table.component';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from 'ng-lazyload-image';
import {NpHomePageComponent} from "./views/np-home-page/np-home-page.component";
import { DialogMediaSheetComponent } from './graphics/components/dialog-media-sheet/dialog-media-sheet.component';
import {TruncatePipe} from "./data/pipes/truncate.pipe";

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        ProductsPageComponent,
        ImportPageComponent,
        AccountPageComponent,
        AuthenticationComponent,
        DialogResetPasswordComponent,
        UserNameComponent,
        SupplierInformationComponent,
        MainPageComponent,
        SidebarContentComponent,
        LogoSupplierComponent,
        PieChartComponent,
        UserInformationComponent,
        ProductTableExpandedDetailViewComponent,
        ProductTableCellViewComponent,
        DialogElementWriterComponent,
        DialogLoaderComponent,
        SnackBarComponent,
        LoaderSpinnerComponent,
        LanguageSelectorComponent,
        MediaCardsComponent,
        FavoritePageComponent,
        FavoriteTableComponent,
        FavoriteSelectorComponent,
        FavoritePipe,
        TruncatePipe,
        ProductTableComponent,
        ProductTableExpandedComponent,
        DialogProductSheetComponent,
        DialogValidationExport,
        DialogValidationExportFavorite,
        MediasPageComponent,
        MediaTableComponent,
        NpHomePageComponent,
        CardsComponent,
        CardComponent,
        NoInstanceFoundComponent,
        NpElementsTableViewComponent,
        NpElementsTableHeaderComponent,
        ProductFilterViewComponent,
        ElementsTableComponent,
        DialogMediaSheetComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        StoreModule.forRoot({
            [authenticationFeatureKey]: authenticationReducer,
            [favoriteFeatureKey]: FavoriteReducer
        }, {}),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
        EffectsModule.forRoot([AuthenticationEffects, FavoriteEffect]),
        NgOptimizedImage,
        LazyLoadImageModule
    ],
    providers: [environment.providers,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}],
    bootstrap: [AppComponent],
    exports: [FavoritePageComponent, MediaCardsComponent, LoaderSpinnerComponent],
})
export class AppModule {
}
