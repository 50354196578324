import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-np-elements-table-header',
  templateUrl: './np-elements-table-header.component.html',
  styleUrls: ['./np-elements-table-header.component.scss']
})
export class NpElementsTableHeaderComponent {
  @Input() filterTitle: string;

}
