<div>
    <h3>Accueil</h3>
</div>
<mat-divider class="my-3"></mat-divider>
<div>
    <mat-form-field appearance="outline" style="min-width: 500px;">
        <mat-label>Rechercher</mat-label>
        <input type="text"  matInput (keyup.enter)="searchByKeyword($event)" />
        <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search">
    </mat-form-field>
</div>
<div>
    <app-loader-spinner *ngIf="isLoadingResults"></app-loader-spinner>
    <app-media-card *ngIf='medias$ | async as data'
                    [filteredCard]="data"
    ></app-media-card>
</div>
