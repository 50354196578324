import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators,} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AlertService} from '../../../data/services/alert.service';
import {SpAuthenticationService} from '../../../data/services/sp-authentication.service';
import {SpLoaderService} from '../services/sp-loader.service';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-dialog-reset-password",
  templateUrl: "./dialog-reset-password.component.html",
  styleUrls: ["./dialog-reset-password.component.scss"],
})
export class DialogResetPasswordComponent implements OnInit, OnDestroy {
  loading = false;
  public loginValid = true;
  private _subscription: Subscription;
  PASSWORD_RESET_REQUEST_SUCCESS = `Your password reset request has been successfully sent.`;
  emailFormControl = new UntypedFormControl("", [
    Validators.email,
    Validators.required,
  ]);

  constructor(
    private _alertService: AlertService,
    private _authenticationService: SpAuthenticationService,
    private _loaderService: SpLoaderService,
    public _dialogRef: MatDialogRef<DialogResetPasswordComponent>
  ) {}

  ngOnInit() {
    this._dialogRef.updatePosition({ top: `${200}px` });
  }

  public onSubmit() {
    this.loginValid = true;
    this.loading = true;

    if (this.emailFormControl.invalid) {
      this.loginValid = false;
      this.loading = false;
    } else {
      this._unsubscribe();

      this._subscription = this._authenticationService
        .resetPassword(this.emailFormControl.value)
        .subscribe(
          () => {
            this.loginValid = true;
            this.loading = false;
            this._loaderService.openSnackBar(
              this.PASSWORD_RESET_REQUEST_SUCCESS
            );
            this.close();
          },
          (error) => {
            const message = error && error.message ? error.message : error;
            this._alertService.error(message);
            this.loginValid = false;
            this.loading = false;
          }
        );
    }
  }

  private _unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  close() {
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this._unsubscribe();
  }
}
