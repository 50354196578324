
<div class="card">
    <p-dataView #dv [value]="elements" [layout]="layout">
        <ng-template pTemplate="header">
            <div class="flex justify-content-end  gap-3">
                                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText class="np-input-custom" placeholder="Rechercher"/>
                </span>
                <p-dataViewLayoutOptions [layout]="layout"></p-dataViewLayoutOptions>
            </div>
        </ng-template>
        <ng-template let-product pTemplate="listItem">
            <div class="col-12 custom-over">
                <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
                         [src]="product.urlImage"
                         [alt]="product.label"
                         loading="lazy"/>
                    <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div class="text-2xl font-bold text-900">{{ product.label }}</div>
                            <div class="flex align-items-center gap-3">
                                <span class="flex align-items-center gap-2">
                                    <i class="pi pi-tag"></i>
                                    <span class="font-semibold">{{ 'Famille' }}</span>
                                </span>
                                <pan class="np-tag" [style.background]="product.status.Color">{{product.status.Libelle}}</pan>
                            </div>
                            <div class="flex align-items-center gap-3">
                                <span class="flex align-items-center gap-2" title="Date de création">
                                    <i class="pi pi-file-edit"></i>
                                    <span class="font-semibold">{{ product.modificationDate }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
<!--                            <span class="text-2xl font-semibold">{{'$' }}</span>-->
                            <button pButton icon="pi pi-eye" class="md:align-self-end mb-2 p-button-rounded" title="Ouvrir"></button>
                            <button pButton icon="pi pi-heart" class="md:align-self-end mb-2 p-button-rounded" title="Ajouter à mes favoris"></button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template let-product pTemplate="gridItem">
            <div class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                <div class="p-4 border-1 surface-border surface-card border-round">
                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="flex align-items-center gap-2">
                        <i class="pi pi-tag"></i>
                        <span class="font-semibold">{{ product.label }}</span>
                    </span>
                        <span class="font-semibold">{{ 'Famille' }}</span>
                    </div>
                    <div class="flex flex-column align-items-center gap-3 py-5">
                        <img class="w-9 shadow-2 border-round"
                             [src]="product.urlImage"
                             [alt]="product.label"
                        />
                        <div class="text-2xl font-bold">{{ product.name }}</div>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <pan class="np-tag" [style.background]="product.status.Color">{{product.status.Libelle}}</pan>
                        <button pButton icon="pi pi-heart" class="md:align-self-end mb-2 p-button-rounded" title="Ajouter à mes favoris"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
