import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favorite-table',
  templateUrl: './favorite-table.component.html',
  styleUrls: ['./favorite-table.component.scss']
})
export class FavoriteTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
