import {Component, OnInit} from '@angular/core';
import {NpCard, ProductCardAction} from '../../data/models/np-card';
import {CardsFacade, MediaFacade, ProductsFacade} from '@data/facades';
import {Cards} from "@data/types";
import {ParamsFilter} from "@data/models";
import {SpDicoCarac} from "@data/constants";
import {catchError, tap} from "rxjs/operators";
import {EMPTY, Observable} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AsyncPipe, NgIf} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {AppModule} from "../../app.module";
import {ParamsFilterBuilder} from "@data/builders";
import {ThumbsSizeService} from "../../data/services/thumbs-size.service";

@Component({
    selector: 'app-np-home-page',
    templateUrl: './np-home-page.component.html',
    styleUrls: ['./np-home-page.component.scss'],
})
export class NpHomePageComponent implements OnInit {
    private _cards: NpCard[];
    private paramFilterBuilder: ParamsFilterBuilder;
    cards: Cards[];
    medias$: Observable<Cards[]>;

    public productCardBtnActions: ProductCardAction;
    isLoadingResults = false

    constructor(private _cardsFacade: CardsFacade,
                private _mediaFacade: MediaFacade,
                private _snackBar: MatSnackBar) {
        // Definition of card actions
        this.productCardBtnActions = this._cardsFacade.productCardBtnActions;
    }

    ngOnInit(): void {
        this.isLoadingResults = true;
        this._getMedias();
    }
    searchByKeyword($event): void {
        // this.isLoadingResults = true;
        // const paramsFilter: ParamsFilter = this.paramFilterBuilder.withKeyword($event.target.value ?? '').build();
        // this._getMedias();
    }
    _getMedias() {
        this.medias$ = this._mediaFacade.getNMediasByCustomField().pipe(
            tap(() => (this.isLoadingResults = false)),
            catchError(err => {
                this.isLoadingResults = false;
                this._snackBar.open('Une erreur c\'est produite veuillez recharger votre page !');
                return EMPTY;
            })
        );
    }
}
