import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {WsParamsService} from './ws-params.service';
import {first, flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AdvancedSearchDTO, CaracValueResultArg, NPSearchMinResult, NPSearchResult} from '../model';
import {ElementsHelper} from '../helpers';
import {Facet, AdvSearchArgs, ResultSearchType} from '../../../app/data/types';

@Injectable({
    providedIn: 'root',
})
export class SearchRepository {
    private _urlGetAdvancedSearch = '/api/sdk/AdvancedSearch/Get/';
    private _urlSearch = '/api/sdk/AdvSearch/Find';
    private _urlGetTextualFilterCriteria = '/api/sdk/caracvaleur/GetCaracValuesByAdvancedSearchResult';
    private _urlSearchMin = '/api/sdk/AdvancedSearch/Find';
    private _searchFacets = '/api/sdk/AdvSearch/FindFacets';

    constructor(private _http: HttpClient, private _wsParamsService: WsParamsService) {
    }

    public getAdvancedSearch(searchID: number): Observable<AdvancedSearchDTO> {
        return this._wsParamsService.getParams().pipe(
            flatMap(params => {
                return this._http.post(this._urlGetAdvancedSearch, {ID: searchID, TicketID: ''});
            }),
            map((result: AdvancedSearchDTO) => {
                return result;
            })
        );
    }

    public searchFacets(advSearchArgs: AdvSearchArgs): Observable<Facet[]> {
        advSearchArgs.Paths = [[]];
        return this._http
            .post<{ ResultCount?: number; Facets: Facet[] }>(this._searchFacets, advSearchArgs)
            .pipe(map(result => _.sortBy(result.Facets, 'DCExtID')));
    }

    public Search(config: any): Observable<NPSearchResult> {
        return this._http.post<ResultSearchType>(this._urlSearch, config).pipe(
            map(data => {
                if (data?.Results != null) {
                    const resultCount: number = data.ResultCount;
                    const resultsElements = ElementsHelper.rebuild(data['Results']);
                    const resultsElementsArray = [];
                    resultsElements.forEach(element => {
                        resultsElementsArray.push(element);
                    });
                    return {
                        resultsCount: resultCount,
                        elements: resultsElementsArray,
                    };
                } else {
                    return {
                        resultsCount: 0,
                        elements: [],
                    };
                }
            }),
            first()
        );
    }

    /**
     * Retourne minifié de la recherche avancée
     * param config
     * constructor
     */
    public SearchMin(config: any): Observable<NPSearchMinResult> {
        return this._wsParamsService.getParams().pipe(
            flatMap(params => {
                return this._http.post<NPSearchMinResult>(this._urlSearchMin, config);
            }),
            first()
        );
    }

    getTextualFilterCriteria(textualFilterConf: CaracValueResultArg): Observable<string[]> {
        return this._http.post<string[]>(this._urlGetTextualFilterCriteria, textualFilterConf);
    }
}
