import {EntityName} from '../../../lib/data/model/portal';
import {RouteName} from './route.constants';
import {NpEntity} from '../models/np-entity.models';

export const NP_ENTITY_CONFIG: Map<string, NpEntity> = new Map([
    [EntityName.Consultation, {
        name: EntityName.Consultation,
        routeName: RouteName.CONSULTATION
    }],
    [EntityName.MediaLibrary, {
        name: EntityName.MediaLibrary,
        routeName: RouteName.MEDIA_LIBRARY
    }],
    [EntityName.Supplier, {
        name: EntityName.Supplier,
        routeName: RouteName.SUPPLIER
    }],
]);

