import {BreakpointObserver} from '@angular/cdk/layout';
import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild,} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {delay, filter} from 'rxjs/operators';
import {MatSidenav} from "@angular/material/sidenav";

@Component({
    selector: 'lib-portal-skeleton',
    templateUrl: './portal-skeleton.component.html',
    styleUrls: ['./portal-skeleton.component.scss'],
})
export class PortalSkeletonComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSidenav)
    sidenav!: MatSidenav;
    isLogin = false;
    @Output() account = new EventEmitter<boolean>();
    @Output() logout = new EventEmitter<boolean>();

    constructor(private observer: BreakpointObserver, private router: Router) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.observer
            .observe(['(max-width: 800px)'])
            .pipe(delay(1))
            .subscribe((response) => {
                if (response.matches) {
                    this.sidenav.mode = 'over';
                    this.sidenav.close();
                } else {
                    this.sidenav.mode = 'side';
                    this.sidenav.open();
                }
            });

        this.router.events
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe(() => {
                if (this.sidenav.mode === 'over') {
                    this.sidenav.close();
                }
            });
    }

    goToAccount() {
        this.account.emit(true);
    }

    logoutEvent() {
        this.logout.emit(true);
    }
}
