<mat-toolbar class="mat-elevation-z2">
    <div>
        <button
                class="burger-button"
                mat-icon-button
                *ngIf="sidenav.mode === 'over'"
                (click)="sidenav.toggle()"
        >
            <mat-icon *ngIf="!sidenav.opened"> menu</mat-icon>
            <mat-icon *ngIf="sidenav.opened"> close</mat-icon>
        </button>
        <span class="content-logo">
            <ng-content select="[logoSupplier]"></ng-content>
        </span>
    </div>
    <div class="d-flex align-items-center">
        <ng-content select="[favorite]"></ng-content>
        <ng-content select="[languageSelector]"></ng-content>
        <ng-content select="[userName]"></ng-content>
    </div>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z0">
        <div class="sidebar-content">
            <ng-content select="[sidebarContent]"></ng-content>
            <!--      <div>-->
            <!--        <mat-divider></mat-divider>-->
            <!--        <button mat-button class="menu-button" (click)="goToAccount()">-->
            <!--          <img src="./assets/icons/user.svg" class="fontawesome-icon-custom" alt="user">-->
            <!--          <span>Mon compte</span>-->
            <!--        </button>-->
            <!--        <button mat-button class="menu-button" (click)="logoutEvent()">-->
            <!--          <img src="./assets/icons/arrow-right.svg" class="fontawesome-icon-custom" alt="logout">-->
            <!--          <span>Déconnexion</span>-->
            <!--        </button>-->
            <!--      </div>-->
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content mat-elevation-z8">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
