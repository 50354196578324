<div>
  <div
    *ngIf="
      widgetItem.WidgetType == enumWidgetTypes.EP ||
      widgetItem.WidgetType == enumWidgetTypes.ER
    "
  >
    <mat-card class="main-card">
      <mat-card-header>
        <mat-icon>pie_chart</mat-icon>
        <mat-card-title
          [npUiTranslate]="widgetItem.Title"
          [title]="widgetItem.Title"
        >
          {{ widgetItem.Title }}
        </mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="mt-4">
        <div class="loadingSpinner" *ngIf="!listElementStats">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
        <div class="d-flex px-4 py-4">
          <div *ngIf="listElementStats" class="col-6">
            <canvas
              baseChart
              [datasets]="chartData1"
              [labels]="chartLabels"
              [options]="chartOptions"
              [legend]="chartLegend"
              [colors]="chartColors"
              [chartType]="chartType"
            ></canvas>
          </div>
          <div class="stats-items col-6" *ngIf="listElementStats">
            <div
              class="stats-elements"
              *ngFor="let elementStat of listElementStats; let i = index"
            >
              <div class="stats-lib">
                <div class="lib-round" [style.background]="colors[i]"></div>
                <span [npUiTranslate]="elementStat.Label">{{
                  elementStat.Label
                }}</span>
              </div>
              <div class="value">
                {{ elementStat.Value }}
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-footer>
        <div *ngIf="listElementStats" style="padding: 15px">
          <span *ngIf="listElementStats && widgetItem.WidgetType === 3"
            >{{ listElementStats[0]?.Total }}
            <span npUiTranslate="_References"></span>
          </span>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>

  <div
    *ngIf="
      widgetItem.WidgetType == enumWidgetTypes.TCP ||
      widgetItem.WidgetType == enumWidgetTypes.TCR
    "
  >
    <mat-card class="main-card">
      <mat-card-header>
        <mat-icon>pie_chart</mat-icon>
        <mat-card-title
          [npUiTranslate]="widgetItem.Title"
          [title]="widgetItem.Title"
        >
          {{ widgetItem.Title }}
        </mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="mt-4">
        <div class="loadingSpinner" *ngIf="!listElementsRate">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
        <div class="d-flex px-4 py-4">
          <div *ngIf="listElementsRate" class="col-6">
            <canvas
              baseChart
              [datasets]="chartData2"
              [labels]="chartLabels"
              [options]="chartOptions"
              [colors]="chartColors"
              [legend]="chartLegend"
              [chartType]="chartType"
            >
            </canvas>
          </div>
          <div class="stats-items col-6" *ngIf="listElementsRate">
            <div
              class="stats-elements"
              *ngFor="let elementStat of listElementsRate; let i = index"
            >
              <div class="stats-lib">
                <div class="lib-round" [style.background]="colors[i]"></div>
                <span *ngIf="elementStat.Percentage !== 100">
                  {{ elementStat.Percentage - 24 }} -
                  {{ elementStat.Percentage }} %</span
                >
                <span *ngIf="elementStat.Percentage === 100"> 100 %</span>
              </div>
              <div class="value">
                {{ elementStat.Value }}
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-footer>
        <div *ngIf="listElementsRate" style="padding: 15px">
          <span *ngIf="listElementsRate && widgetItem.WidgetType === 5"
            >{{ getTotal(listElementsRate) }}
            <span npUiTranslate="_ReferencesToComplete"></span
          ></span>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
