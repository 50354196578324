import {inject, Injectable} from '@angular/core';
import {EntityParameter, EntityMenuItem} from '../models/np-entity.models';
import {Observable, of} from 'rxjs';
import {RouteResolverService} from '../services/routes/route-resolver.service';
import {RouteName} from '../constants/route.constants';
import {NpInstanceService} from '../services/np-instance.service';

@Injectable({
    providedIn: 'root'
})
export class EntityFacade {
    private _routeResolverService = inject(RouteResolverService);
    private _npInstanceService = inject(NpInstanceService);

    private _menuProductsItems: EntityMenuItem[] = [
        {
            associatedRouteName: RouteName.HOME,
            label: 'Accueil',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.HOME)
        },
        {
            associatedRouteName: RouteName.PRODUCTS,
            label: 'Produits',
            iconName: 'subject',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.PRODUCTS)
        },
    ];

private _menuMediasItems: EntityMenuItem[] = [
        {
            associatedRouteName: RouteName.HOME,
            label: 'Accueil',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.HOME)
        },
        {
            associatedRouteName: RouteName.MEDIAS,
            label: 'Médias',
            iconName: 'perm_media',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.MEDIAS)
        },
    ];
    private _menuSupplierItems: EntityMenuItem[] = [
        {
            associatedRouteName: RouteName.HOME,
            label: 'Accueil',
            iconName: 'home',
            selected: true,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.HOME)
        },
        {
            associatedRouteName: RouteName.PRODUCTS,
            label: 'Produits',
            iconName: 'subject',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.PRODUCTS)
        },
        {
            associatedRouteName: RouteName.IMPORT,
            label: 'Importer',
            iconName: 'upload',
            selected: false,
            action: () => this._routeResolverService.navigateToCurrentEntityRoute(RouteName.IMPORT)
        },
    ];
    private _menuItemMap = new Map<RouteName, EntityMenuItem[]>([
            [RouteName.CONSULTATION, this._menuProductsItems],
            [RouteName.MEDIA_LIBRARY, this._menuMediasItems],
            [RouteName.SUPPLIER, this._menuSupplierItems],
        ]
    );

    getEntityParameters(entityName: string): Observable<EntityParameter> {
        return of({
            menuItems: this._menuItemMap.get(this._npInstanceService.currentEntity.routeName) || []
        });
    }
}
