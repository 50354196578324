<button mat-button [matMenuTriggerFor]="menu">{{
    currentUser ? currentUser.FirstName + " " + currentUser.LastName : ""
    }}</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item class="menu-button" (click)="goToAccount()" >
        <img src="./assets/icons/user.svg" class="fontawesome-icon-custom" alt="user">
        <span>Mon compte</span>
    </button>
    <button  mat-menu-item class="menu-button" (click)="onLogout()">
        <img src="./assets/icons/arrow-right.svg" class="fontawesome-icon-custom" alt="logout">
        <span>Déconnexion</span>
    </button>
</mat-menu>
