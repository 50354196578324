import {Component, OnInit} from '@angular/core';
import {NpUser} from '@nextpage/np-sdk-data';
import {filter, flatMap} from 'rxjs/internal/operators';
import {SpAuthenticationService} from '../../../data/services/sp-authentication.service';
import {SpUserInfoService} from '@data/services';

@Component({
  selector: "app-user-information",
  templateUrl: "./user-information.component.html",
  styleUrls: ["./user-information.component.scss"],
})
export class UserInformationComponent implements OnInit {
  public currentUser: NpUser;

  constructor(
    private _userInfoService: SpUserInfoService,
    private _authenticationService: SpAuthenticationService
  ) {}

  ngOnInit(): void {
    this._fetchUserInfos();
  }

  private _fetchUserInfos() {
    this._authenticationService
      .afterAuth()
      .pipe(
        filter((response) => {
          return response;
        }),
        flatMap(() => {
          return this._userInfoService.getCurrentUser();
        })
      )
      .subscribe((response) => {
        this.currentUser = response;
      });

    this._userInfoService.getCurrentUser().subscribe((response) => {
      this.currentUser = response;
    });
  }
}
