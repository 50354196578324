import {Injectable} from '@angular/core';
import {DicoCarac, NPCaracDate, NPElement} from '@nextpage/np-sdk-data';
import {ElementSummary} from '../models/sp-models';
import {ElementLabels} from '../constants/sp-constants';

@Injectable({
  providedIn: 'root',
})
export class TableHandlerService {
  private _displayedColumns: string[] = Object.getOwnPropertyNames(
    new ElementSummary()
  );

  static getLabelExtIdByElementType(element: NPElement): string {
    if (element) {
      const value = element.Values.get(
        DicoCarac.DTO_SYSTEM_LAST_MODIFIED_DATE_CARACS
      ) as NPCaracDate;
      return value && value.LastModifiedDate
        ? new Date(value.LastModifiedDate).toLocaleString()
        : '';
    }
    return '';
  }

  static getLastModifiedInfos(element: NPElement): NPCaracDate {
    return element
      ? (element.Values.get(
        DicoCarac.DTO_SYSTEM_LAST_MODIFIED_DATE_CARACS
      ) as NPCaracDate)
      : null;
  }

  static toLocalDate(value: NPCaracDate) {
    return value && value.LastModifiedDate
      ? new Date(value.LastModifiedDate).toLocaleDateString()
      : '';
  }

  static toDate(value: NPCaracDate) {
    return value && value.LastModifiedDate
      ? new Date(value.LastModifiedDate)
      : '';
  }

  constructor() {}

}
