import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountPageComponent} from './views/account-page/account-page.component';
import {AuthenticationComponent} from './views/authentication/authentication.component';
import {HomePageComponent} from './views/home-page/home-page.component';
import {ImportPageComponent} from './views/import-page/import-page.component';
import {MainPageComponent} from './views/main-page/main-page.component';
import {ProductsPageComponent} from './views/products-page/products-page.component';
import {FavoritePageComponent} from './views/favorite-page/favorite-page.component';
import {AuthenticationGuard} from './data/guards/authentication.guard';
import {RouteName, RouteParamName} from './data/constants/route.constants';
import {MediasPageComponent} from './views/medias-page/medias-page.component';
import {NpInstanceGuard} from './data/services/guards/np-instance.guard';
import {NoInstanceFoundComponent} from './views/no-instance-found/no-instance-found.component';
import {NpEntityGuard} from './data/services/guards/np-entity.guard';
import {EntityResolver} from './data/services/resolvers/entity-resolver';
import {NpElementsTableViewComponent} from './views/np-elements-table-view/np-elements-table-view.component';
import {NpHomePageComponent} from './views/np-home-page/np-home-page.component';
import {PageResolver} from './data/services/resolvers/page-resolver';

const routes: Routes = [
    {
        path: `${RouteName.LOGIN}/:${RouteParamName.INSTANCE_ID}`,
        component: AuthenticationComponent,
        canActivate: [NpInstanceGuard]
    },
    {
        path: `${RouteName.CONSULTATION}/:${RouteParamName.INSTANCE_ID}`,
        component: MainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RouteName.HOME, component: HomePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.ELEMENTS_TABLE_VIEW, component: NpElementsTableViewComponent, data: {}},
            {path: RouteName.PRODUCTS, component: ProductsPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.FAVORITE, component: FavoritePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.ACCOUNT, component: AccountPageComponent, resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: `${RouteName.MEDIA_LIBRARY}/:${RouteParamName.INSTANCE_ID}`,
        component: MainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RouteName.HOME, component: NpHomePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.MEDIAS, component: MediasPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.FAVORITE, component: FavoritePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.ACCOUNT, component: AccountPageComponent, resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: `${RouteName.SUPPLIER}/:${RouteParamName.INSTANCE_ID}`,
        component: MainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RouteName.HOME, component: HomePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.PRODUCTS, component: ProductsPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.IMPORT, component: ImportPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RouteName.FAVORITE, component: FavoritePageComponent , resolve: {pageParameter: PageResolver}},
            {path: RouteName.ACCOUNT, component: AccountPageComponent , resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: RouteName.NO_INSTANCE_FOUND, component: NoInstanceFoundComponent
    },
    {
        path: '', redirectTo: RouteName.NO_INSTANCE_FOUND, pathMatch: 'full'
    },
    {
        path: '**', component: NoInstanceFoundComponent
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
