<div>
    <h3>Favoris</h3>
</div>
<mat-divider class="my-3"></mat-divider>
<div *ngIf="(favoritesState | async).length > 0; else noFavorite">
    <div class='mat-form-field-text'>
        <mat-form-field appearance='outline' class="field-search-custom">
            <mat-label>Rechercher</mat-label>
            <input type='text' matInput (keyup)="applyFilter($event)"/>
            <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search">
        </mat-form-field>
        <div class='export-button'>
            <button mat-flat-button color='primary' (click)="openDialog()">
                <i class="pr-1 pi pi-download"></i>
                Exporter
            </button>
        </div>
    </div>
    <div class="mat-elevation-z2" style="overflow-y: auto;">
        <table mat-table [dataSource]='dataSource' multiTemplateDataRows>
            <ng-container [matColumnDef]='column' *ngFor='let column of columnsToDisplay'>
                <th mat-header-cell *matHeaderCellDef>
                    {{ headerLabels[column] }}
                </th>
                <td mat-cell *matCellDef="let element" title="Cliquez pour afficher plus d'informations">
                    <ng-container *ngIf="column === 'overview'">
                        <img
                                [src]="element.urlImage"
                                class="imageUrlStyle"
                                height="125"
                                alt="Aperçu du média"
                        />
                    </ng-container>
                    <ng-container *ngIf="column === 'label'">
                        {{element.label}}
                    </ng-container>
                    <ng-container *ngIf="column === 'actions'">
                        <div class="d-flex">
                            <button mat-icon-button class="icon-more-info"
                                    (click)='showMoreInfos(element); $event.stopPropagation()'>
                                <img ngSrc="./assets/icons/eye.svg" class="fontawesome-icon-custom icon-eye" alt="eye"
                                     height="16"
                                     width="16">
                            </button>
                            <button mat-icon-button
                                    (click)="removeFromFavoriteList(element)">
                                <mat-icon [ngStyle]="{ color: element.selected ? 'red' : '' }">favorite</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='columnsToDisplay'></tr>
            <tr
                    mat-row
                    *matRowDef='let row; columns: columnsToDisplay'
                    class='expanded-detail-element-row'
                    [class.example-expanded-row]='expandedElement === row'
                    (click)='expandedElement = expandedElement === row ? null : row'
            ></tr>
        </table>

        <mat-paginator [pageSize]="10" [length]="(favoritesState | async).length" [pageSizeOptions]="[10, 25, 50, 100]"
                       showFirstLastButtons></mat-paginator>

    </div>
</div>
<ng-template #noFavorite>
    <div class="noCardsAvailable">Vous n'avez pas de favoris !</div>
</ng-template>
