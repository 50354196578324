<div>
  <button mat-button class="menu-button" (click)="goToFavorite()">
    <mat-icon matBadge="{{ (favoritesState | async).length > 0  ? (favoritesState | async).length : ''}}"
              matBadgePosition="above after"
              matBadgeSize="small"
              matBadgeColor="accent">favorite_border
    </mat-icon>
    <span style="margin-left: 4px;">Favoris</span>
  </button>
</div>
