import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ElementSummary, Favorite, MediaSummary, ProductSummary} from "@data/models";
import {TABLE_HEADER_LABELS} from "@data/constants";
import {SelectionModel} from "@angular/cdk/collections";
import {Observable} from "rxjs";
import {SpUserInfoService} from "@data/services";
import {FileReaderService} from "@nextpage/np-sdk-data";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {ProductsFacade} from "@data/facades";
import {selectFavoritesList} from "@store/selector";
import {FavoriteActions} from "@store/action";
import {
  DialogProductSheetComponent
} from "../../../graphics/components/dialog-product-sheet/dialog-product-sheet.component";
import {DialogMediaSheetComponent} from "../../../graphics/components/dialog-media-sheet/dialog-media-sheet.component";

@Component({
  selector: 'app-media-table',
  templateUrl: './media-table.component.html',
  styleUrls: ['./media-table.component.scss']
})
export class MediaTableComponent  implements OnInit {
  @Input() columnsToDisplay: string[] = [];
  @Input() dataSource: MatTableDataSource<MediaSummary>;
  @Input() public headerLabels = TABLE_HEADER_LABELS;
  private visualFields: any;
  expandedElement: ElementSummary | null;
  selection = new SelectionModel<MediaSummary>(true, []);
  selectedItem: any = [];
  favoritesState$: Observable<Favorite[]>;

  constructor(
      private _userInfoService: SpUserInfoService,
      private _fileReaderImage: FileReaderService,
      private store: Store,
      public dialog: MatDialog,
      private _fileReaderService: FileReaderService,
      private productsFacade: ProductsFacade,
  ) {
    this.favoritesState$ = this.store.select(selectFavoritesList);
  }

  ngOnInit(): void {
  }

  getSelectedValue(item) {
    this.selectedItem.push(item);
  }

  toggleFavorite(elementSummery: ElementSummary, $event: Event) {
    $event.stopPropagation();
    elementSummery.isFavoriteElement = !elementSummery.isFavoriteElement;
    this.store.dispatch(
        FavoriteActions.toggleFavorite({
          favorites: {
            extID: elementSummery.element.ExtID,
            urlImage: elementSummery.urlImage,
            label: elementSummery.label,
            element: elementSummery.element,
            modificationDate: elementSummery.modificationDate,
            selected: elementSummery.isFavoriteElement,
          },
        })
    );
  }

  showMoreInfos(element) {
    const mediaSize = element.element.getValueLien('DC_MEDIA_METADATA_Size').Value;
    const mediaSizeInMo = (mediaSize / 1048576).toFixed(2)

    this.dialog.open(DialogMediaSheetComponent, {
      hasBackdrop: true,
      disableClose: false,
      height: '95%',
      width: '80%',
      data: {...element, mediaSizeInMo}
    });
  }
}
